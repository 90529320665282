<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToMachineToollijsten">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Tools in toollijst - {{ machineNaam }} - {{ toollijstNaam }}</strong>
        </CCardHeader>
        <CCardBody>
          <CButton @click="viewToolDetails" :disabled="toolsSelectedRow === undefined" color="primary" class="btn-md mb-2">
            Bekijk toolgegevens
          </CButton>
          &nbsp;
          <dynamic-grid
            name="TabletTLTools"
            :metadata="toollijstToolsMetadata"
            :data="toollijstToolsData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
      <div :style="toolViewerModalActive ? undefined : 'display:none'">
        <tool-viewer-modal
          :isModalActive="toolViewerModalActive"
          :werkelijkeTool="werkelijkeTool"
          :visible="toolViewerModalActive"
          @close="
            () => {
              toolViewerModalActive = false;
            }
          "
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import ToolViewerModal from '@/components/ToolViewerModal.vue';
import { computed, watch, toRefs, ref } from 'vue';
import CrudService from '../../services/CrudService';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';
import { useRouter } from 'vue-router';

export default {
  name: 'TabletToollijstTools',
  components: { DynamicGrid, ToolViewerModal, CIcon },
  props: {
    toollijstId: {
      required: true
    },
    machineId: {
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { toollijstId: toollijstId, machineId: machineId } = toRefs(props);

    if (!store.state.dynamicgrid['TabletTLTools']) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'TabletTLTools');
      store.dispatch('tablettoollijsttools/getToolsMetadata');
    }

    store.dispatch('tablettoollijsttools/getToolsData', toollijstId.value);

    const toolViewerModalActive = ref(false);

    const toolsSelectedRow = computed(() => {
      if (store.state.dynamicgrid['TabletTLTools'].selectedRows.length > 0) {
        return store.state.dynamicgrid['TabletTLTools'].selectedRows[0];
      }
      return undefined;
    });

    watch(toolsSelectedRow, () => {
      if (toolsSelectedRow.value) {
        store.commit('tablettoollijsttools/SET_SELECTED_TOOL_ID', toolsSelectedRow.value.toolId);
      }
    });

    const toollijstToolsMetadata = computed(() => store.state.tablettoollijsttools.toollijstToolsMetadata);
    const toollijstToolsData = computed(() => store.state.tablettoollijsttools.toollijstToolsData);
    const werkelijkeTool = computed(() => store.state.tablettoollijsttools.werkelijkeTool);

    const machineNaam = computed(() => store.state.tablettoollijsttools.machineNaam);
    const toollijstNaam = computed(() => store.state.tablettoollijsttools.toollijstNaam);

    function viewToolDetails() {
      if (toolsSelectedRow.value.toolId) {
        CrudService.getDataByQueries('werkelijketools/findby', [
          {
            queryName: 'toolId',
            queryValue: toolsSelectedRow.value.toolId
          },
          {
            queryName: 'machineId',
            queryValue: machineId.value
          }
        ])
          .then((response) => {
            if (response.data.success) {
              store.commit('tablettoollijsttools/SET_WERKELIJKE_TOOL', response.data.value);
              toolViewerModalActive.value = true;
            } else {
              store.commit('ADD_TOAST_ERROR', 'Werkelijke tool kon niet worden gevonden.', { root: true });
            }
          })
          .catch((error) => store.commit('ADD_TOAST_ERROR', error, { root: true }));
      }
    }

    function goToMachineToollijsten() {
      router.push({
        name: 'tabletmachinetoollijsten',
        params: {
          machineId: machineId.value
        }
      });
    }

    return {
      toollijstToolsMetadata,
      toollijstToolsData,
      toolsSelectedRow,
      viewToolDetails,
      toolViewerModalActive,
      werkelijkeTool,
      cilArrowLeft,
      goToMachineToollijsten,
      machineNaam,
      toollijstNaam
    };
  }
};
</script>