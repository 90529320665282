<template>
  <!-- eslint-disable -->
  <v-select
      v-memo="[config.enforce, config.options, params.data[config.valuePropertyName]]"
    :options="config.enforce ?? config.options"
    v-if="config?.options?.length || (config && !config.displayTextIfNoOptions)"
    v-model="params.data[config.valuePropertyName]"
    @update:model-value="onOptionChanged"
    placeholder="Uw Keuze"
    :searchable="true"
    style="font-size: 12px"
    :disabled="(!config?.options?.length && !params.data[config.valuePropertyName])"
    :clearable="false"
    :label="config.label"
    :reduce="(option) => option[config.key ?? config.label]"
  >
    <template #option="option">
      <span>{{ option[config.label] }}</span>
      <span v-if="option.machine?.nummer"> ({{ option.machine?.nummer }})</span>
    </template>
    <template #no-options>
      Geen opties beschikbaar.
    </template>
  </v-select>
  <span v-else>{{ config?.displayTextIfNoOptions ?? 'n.v.t.' }}</span>
</template>


<script>
import { defineComponent, toRefs, computed, watch } from 'vue';
import 'vue-select/dist/vue-select.css';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
  name: 'DropdownCellRenderer',
  props: {
    params: {
      required: false
    }
  },
  setup(props) {
    const { params: parameters } = toRefs(props);

    const columnName = computed(() => parameters.value.column.colId);
    const config = computed(() =>
      columnName.value && parameters.value.data.dropdownConfiguration?.length
        ? parameters.value.data.dropdownConfiguration.filter((x) => x.valuePropertyName == columnName.value)[0]
        : parameters.value.data.dropdownConfiguration
    );

    function onOptionChanged(option) {
      let colId = parameters.value.column.colId;
      parameters.value.node.setDataValue(colId, option);

      if (config.value.valuePropertyName == 'replacement' ) {
        const suggestionWerkelijkeTools =
          parameters.value.data.dropdownConfiguration.filter((x) => x.valuePropertyName == 'replacement')[0]?.options ?? [];
        const toolnummersConfig = parameters.value.data.dropdownConfiguration.filter((x) => x.valuePropertyName == 'toolnummer')[0];
        const toolnummers = toolnummersConfig?.options ?? [];

        if (toolnummers.length && suggestionWerkelijkeTools.length) {
          const wt = suggestionWerkelijkeTools.filter((x) => x.unieknummer == option)[0];

          if (wt?.vastToolnummer && wt.vastToolnummer > 0) {
            toolnummersConfig.enforce = [wt.vastToolnummer];
            parameters.value.node.setDataValue('toolnummer', wt.vastToolnummer);
            return;
          }

          if (parameters.value.data.machine.suggestToolnummers) {
            const firstAvailable = toolnummers
              .map((x) => x.key)
              .filter(
                (x) =>
                  x &&
                  (!parameters.value.api.rowModel.rowsToDisplay.map((r) => r.data.toolnummer).includes(x) || parameters.value.node.data.toolnummer == x)
              )[0];

            parameters.value.node.setDataValue('toolnummer', firstAvailable);
          }
          toolnummersConfig.enforce = undefined;
        }
      }
    }
    let isExecutedOnce = false;
    watch(
      config,
      () => {
        if (config.value && !config.value?.options.length && config.value.useDefaultTextAsValue) {
          let colId = parameters.value.column.colId;
          parameters.value.node.setDataValue(colId, config.value.displayTextIfNoOptions);
          if (!isExecutedOnce && config.value.displayTextIfNoOptions == 'Voorinstellen') {
            const toolnummersConfig = parameters.value.data.dropdownConfiguration.filter((x) => x.valuePropertyName == 'toolnummer')[0];
            if (toolnummersConfig && parameters.value.data?.werkelijkeTool?.vastToolnummer) {
              isExecutedOnce = true;
              parameters.value.node.setDataValue('toolnummer', parameters.value.data.werkelijkeTool.vastToolnummer);
            }
          }
        }
      },
      { immediate: true }
    );

    function isPopup() {
      return true;
    }

    function refresh() {
      return false;
    }

    return {
      parameters,
      config,
      isPopup,
      onOptionChanged,
      refresh
    };
  }
});
</script>








































































<style>


</style>