import { createStore } from 'vuex';
import wissellijsten from './modules/wissellijsten';
import dynamicform from './modules/dynamicform';
import dynamicgrid from './modules/dynamicgrid';
import dynamicgridview from './modules/dynamicgridview';
import dynamicformview from './modules/dynamicformview';
import toolsview from './modules/toolsview';
import toolscrud from './modules/toolscrud';
import componentsview from './modules/componentsview';
import componentscrud from './modules/componentscrud';
import voorinstelorders from './modules/voorinstelorders';
import toollijstenview from './modules/toollijstenview';
import wissellijstenview from './modules/wissellijstenview';
import tabletmachinesview from './modules/tabletmachinesview';
import tabletmachinetoollijsten from './modules/tabletmachinetoollijsten';
import tabletmachinewissellijst from './modules/tabletmachinewissellijst';
import tabletmachinetoolmagazijnen from './modules/tabletmachinetoolmagazijnen';
import tablettoollijsttools from './modules/tablettoollijsttools';
import tabletmachinemagazijnen from './modules/tabletmachinemagazijnen';
import machinesformview from './modules/machinesformview';
import tabletversletentools from './modules/tabletversletentools';
import toollijstencrud from './modules/toollijstencrud';
import tabletmachinewissellijstenhistory from './modules/tabletmachinewissellijstenhistory';
import tabletwissellijstenhistoryregels from './modules/tabletwissellijstenhistoryregels';
import tabletvervangingenhistory from './modules/tabletvervangingenhistory';
import tabletversletenhistory from './modules/tabletversletenhistory';
import dynamicmodal from './modules/dynamicmodal';
import tablettoolregisterimport from './modules/tablettoolregisterimport';
import orderscrud from './modules/orderscrud';
import CrudService from '../services/CrudService';

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    theme: 'default',
    baseURL: '',
    defaultToPresetEverything: '',
    keycloakSettings: {
      authUrl: '',
      realm: '',
      clientId: ''
    },
    error: undefined,
    toastErrors: [],
    toastSuccesses: [],
    toastWarnings: [],
    selectedPrinter: {},
    availablePrinters: [],
    user: {
      isAuthenticated: false,
      name: '',
      idToken: '',
      accessToken: '',
      roles: []
    },
    loadingStatus: false,
    version: {},
    lastPage: undefined,
    logo: 'logo.png',
    mainScreens: [],
    listScreens: []
  },
  mutations: {
    ADD_TOAST_ERROR(state, error) {
      state.toastErrors.push(error);
    },
    ADD_TOAST_SUCCESS(state, message) {
      state.toastSuccesses.push(message);
    },
    ADD_TOAST_WARNING(state, message) {
      state.toastWarnings.push(message);
    },
    SET_LOGO(state, logo) {
      state.logo = logo;
    },
    SET_MAIN_SCREENS(state, mainScreens) {
      state.mainScreens = mainScreens;
    },
    SET_LIST_SCREENS(state, listScreens) {
      state.listScreens = listScreens;
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible;
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleTheme(state, payload) {
      state.theme = payload.value;
    },
    SET_ERROR(state, error) {
      state.error = error ?? { message: 'Oops, er ging iets fout!' };
    },
    CLEAR_ERROR(state) {
      state.error = undefined;
    },
    SET_BASEURL(state, url) {
      state.baseURL = url;
    },
    SET_DEFAULT_TO_PRESET_EVERYTHING(state, defaultToPresetEverything) {
      state.defaultToPresetEverything = defaultToPresetEverything;
    },
    logout(state) {
      state.user.isAuthenticated = false;
      state.user.name = '';
      state.user.idToken = '';
      state.user.accessToken = '';
    },
    login(state, payload) {
      state.user.isAuthenticated = true;
      state.user.idToken = payload.idToken;
      state.user.name = payload.name;
      state.user.accessToken = payload.accessToken;
      state.user.roles = payload.roles;
    },
    setAccessToken(state, accessToken) {
      state.user.accessToken = accessToken;
    },
    setName(state, payload) {
      state.user.name = payload.name;
    },
    SET_KEYCLOAK_SETTINGS(state, payload) {
      state.keycloakSettings = payload;
    },
    SET_VERSION(state, payload) {
      state.version = payload;
    },
    SET_LAST_PAGE(state, payload) {
      state.lastPage = payload;
    },
    SET_LOADING_STATUS(state, payload) {
      state.loadingStatus = payload;
    },
    SET_SELECTED_PRINTER(state, payload) {
      payload.printer = state.availablePrinters.filter((p) => p.id == payload.printerId)[0];
      state.selectedPrinter = payload;
    },
    SET_AVAILABLE_PRINTERS(state, payload) {
      state.availablePrinters = payload;
    },
    HANDLE_RESPONSE(state, { response, successMessage = undefined, displayMultipleErrors = false, displayMultipleWarnings = false }) {
      if (!response.success) {
        try {
          if (displayMultipleErrors) {
            state.error = [...new Set(response.messages.filter((x) => x.messageType == 2).map((x) => x.text))].join('\n');
          } else {
            state.toastErrors.push(response.messages.filter((x) => x.messageType == 2)[0].text);
          }
        } catch (err) {
          console.error(err);
          state.toastErrors.push('Oops, er ging iets fout!');
        }
      } else if (successMessage) {
        state.toastSuccesses.push(successMessage);
        const warnings = response.messages.filter((x) => x.messageType == 1);
        if (warnings.length) {
          if (displayMultipleWarnings) {
            state.error = 'Waarschuwingen: ' + [...new Set(warnings.map((y) => y.text))].join('\n');
          } else {
            state.toastWarnings.push(warnings[0].text);
          }
        }
      }
    }
  },
  actions: {
    setError({ commit }, error) {
      commit('SET_ERROR', error);
    },

    getAllPrinters({ commit }) {
      CrudService.getGridData('printers')
        .then((response) => {
          if (response.data.success) {
            commit('SET_AVAILABLE_PRINTERS', response.data.value);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
          throw error;
        });
    },

    getSelectedPrinter({ commit }, username) {
      CrudService.getDataById('printerUsers/byuser', username)
        .then((response) => {
          if (response.data.success) {
            commit('SET_SELECTED_PRINTER', response.data.value);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
          throw error;
        });
    },

    updatePrinter({ commit }, printerUser) {
      CrudService.putData('printerUsers', printerUser.id, printerUser)
        .then((response) => {
          if (response.data.success) {
            commit('SET_SELECTED_PRINTER', response.data.value);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
          throw error;
        });
    },

    addPrinter({ commit }, printerUser) {
      printerUser.printer = undefined;
      CrudService.postData('printerUsers', printerUser)
        .then((response) => {
          if (response.data.success) {
            commit('SET_SELECTED_PRINTER', response.data.value);
          }
        })
        .catch((error) => {
          commit('SET_ERROR', error);
          throw error;
        });
    }
  },
  modules: {
    wissellijsten,
    dynamicform,
    dynamicformview,
    dynamicgrid,
    dynamicgridview,
    toolsview,
    toolscrud,
    componentsview,
    componentscrud,
    voorinstelorders,
    toollijstenview,
    wissellijstenview,
    tabletmachinesview,
    tabletmachinetoollijsten,
    tabletmachinewissellijst,
    tablettoollijsttools,
    tabletmachinemagazijnen,
    machinesformview,
    tabletversletentools,
    toollijstencrud,
    tabletmachinewissellijstenhistory,
    tabletwissellijstenhistoryregels,
    tabletversletenhistory,
    tabletvervangingenhistory,
    tabletmachinetoolmagazijnen,
    dynamicmodal,
    orderscrud,
    tablettoolregisterimport
  }
});
