<template>
  <CCard>
    <CCardHeader>
      <strong>{{ metadata?.modelDisplayName }}</strong>
    </CCardHeader>
    <CCardBody>
      <CButton v-if="!metadata.hideAddButton" @click="onAdd" color="primary" class="btn-sm mb-2"> Toevoegen </CButton>
      <span v-else>Het toevoegen van een werkelijke tool kan alleen via het tools scherm.</span>
      &nbsp;
      <CButton @click="onEdit" :disabled="!selectedRow" color="light" class="btn-sm mb-2"> Wijzigen </CButton>
      &nbsp;
      <CButton
        v-if="!metadata.disableDeleteButton"
        @click="
          () => {
            deleteModalActive = true;
          }
        "
        :disabled="isDeleteButtonDisabled"
        color="danger"
        class="btn-sm mb-2 float-end"
      >
        Verwijderen
      </CButton>
      <dynamic-grid @double-clicked="onEdit" :name="componentName" :data="data" :metadata="metadata" :autoPagination="false" />
    </CCardBody>
  </CCard>
  <confirmation-modal
    :style="deleteModalActive ? undefined : 'display:none'"
    :modalActive="deleteModalActive"
    modalBody="Weet u zeker dat u de geselecteerde rij wilt verwijderen?"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
  <confirmation-modal
    :style="warningModalActive ? undefined : 'display:none'"
    :modalActive="warningModalActive"
    :modalBody="`Bevestig dat u op de hoogte bent van de volgende omstandigheden.\n\n${warningToConfirm}`"
    @on-continue="() => onDelete(true)"
    @on-cancel="() => (warningModalActive = false)"
  />
</template>

<script>
import DynamicGrid from './DynamicGrid.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { useRouter } from 'vue-router';
import { computed, toRefs, watch, ref } from 'vue';
import { useStore } from 'vuex';
import usePromise from '@/composables/usePromise';
import CrudService from '@/services/CrudService';

export default {
  components: { DynamicGrid, ConfirmationModal },
  props: {
    name: {
      required: true
    },
    formRouting: {
      required: false
    }
  },
  setup(props) {
    const { name: componentName } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const deleteModalActive = ref(false);

    if (!store.state.dynamicgridview[componentName.value]) {
      store.commit('dynamicgrid/SET_NEW_GRID', componentName.value);
      store.commit('dynamicgridview/SET_NEW_GRID_VIEW', componentName.value);
    }

    const metadata = computed(() => store.state.dynamicgridview[componentName.value].metadata);
    const formRouting = computed(() => store.state.dynamicgridview[componentName.value].metadata.customFormRoute);
    const data = computed(() => store.state.dynamicgridview[componentName.value].rows);

    watch(
      componentName,
      () => {
        store.commit('SET_LAST_PAGE', { name: 'dynamicgridview', params: { name: componentName.value } }, { root: true });

        if (!store.state.dynamicgridview[componentName.value]) {
          store.commit('dynamicgrid/SET_NEW_GRID', componentName.value);
          store.commit('dynamicgridview/SET_NEW_GRID_VIEW', componentName.value);
        }
        if (!metadata.value.properties) {
          const getMetadata = usePromise(() => CrudService.getMetadataByName(componentName.value, 'grid'));

          watch(getMetadata.loading, () => {
            if (getMetadata.loading.value === false) {
              store.commit('dynamicgridview/SET_METADATA', { payload: getMetadata.results.value.data, identifier: componentName.value });
              store.dispatch('dynamicgridview/getRows', { endpoint: componentName.value.replace(' ', ''), identifier: componentName.value });
            }
          });
          getMetadata.createPromise();
        } else {
          store.dispatch('dynamicgridview/getRows', { endpoint: componentName.value.replace(' ', ''), identifier: componentName.value });
        }
      },
      { immediate: true }
    );

    const selectedRow = computed(() => store.state.dynamicgrid[componentName.value].selectedRows[0]);
    const isDeleteButtonDisabled = computed(() => {
      if (!selectedRow.value) return true;
      if (!metadata.value.propertiesRestrictingDeletion?.length) {
        return false;
      }

      for (let prop of metadata.value.propertiesRestrictingDeletion) {
        const value = selectedRow.value[prop];
        if (value && (!Array.isArray(value) || value.length > 0)) return true;
      }

      return false;
    });

    function onAdd() {
      router.push(
        formRouting.value
          ? {
              name: formRouting.value
            }
          : {
              name: 'dynamicformview',
              params: {
                name: componentName.value
              }
            }
      );
    }

    function onEdit() {
      router.push(
        formRouting.value
          ? {
              name: formRouting.value,
              params: {
                id: store.state.dynamicgrid[componentName.value].selectedRows[0]?.id
              }
            }
          : {
              name: 'dynamicformview',
              params: {
                name: componentName.value,
                id: store.state.dynamicgrid[componentName.value].selectedRows[0]?.id
              }
            }
      );
    }

    let warningModalActive = ref(false);
    let warningToConfirm = ref('');
    function onDelete(isWarningConfirmed = false) {
      CrudService.deleteByQueries(
        componentName.value.replace(' ', '') + '/' + selectedRow.value.id,
        isWarningConfirmed ? [{ queryName: 'ignoreWarnings', queryValue: true }] : []
      )
        .then((response) => {
          deleteModalActive.value = false;

          if (response.data.requiresConfirmation) {
            warningToConfirm.value = response.data.messages
              .filter((x) => x.messageType == 4)
              .map((x) => x.text)
              .join('\n');
            warningModalActive.value = true;
            return;
          }

          if (response.data.success) {
            store.commit('dynamicgridview/REMOVE_ROW', { id: selectedRow.value.id, identifier: componentName.value });
            store.state.dynamicgrid[componentName.value].selectedRows = {};
            store.commit('dynamicgrid/REMOVE_ROW', {
              id: store.state.dynamicgrid[componentName.value].selectedRows[0]?.id,
              identifier: componentName.value
            });
            warningModalActive.value = false;
          }

          store.commit(
            'HANDLE_RESPONSE',
            { response: response.data, successMessage: 'De geselecteerde rij is succesvol verwijderd' },
            { root: true }
          );
        })
        .catch((error) => {
          store.commit('ADD_TOAST_ERROR', error, { root: true });
          deleteModalActive.value = false;
          throw error;
        });
    }

    return {
      onEdit,
      onAdd,
      data,
      metadata,
      componentName,
      onDelete,
      deleteModalActive,
      selectedRow,
      isDeleteButtonDisabled,
      warningModalActive,
      warningToConfirm
    };
  }
};
</script>