<template>
  <CModal size="xl">
    <CModalHeader>
      <a
        @click.stop.prevent="
          () => {
            imageIsActive = false;
          }
        "
        v-if="imageIsActive"
      >
        <CIcon class="text-secondary" :icon="cilArrowLeft" size="xl" />
      </a>
      <CModalTitle class="ms-3">{{ imageIsActive ? 'Afbeelding' : 'Werkelijke Tool' }}</CModalTitle>
    </CModalHeader>
    <CModalBody v-if="imageIsActive">
      <CImage rounded class="img-fluid border border-light" style="object-fit: contain" :src="imageActive" />
    </CModalBody>
    <CModalBody v-if="!imageIsActive">
      <CRow class="mb-3">
        <CCol :xs="12" :lg="5">
          <a class="position-relative">
            <CImage
              @click.stop.prevent="displayImage(image)"
              style="object-fit: contain"
              v-if="image"
              rounded
              class="border border-light"
              height="300"
              width="300"
              :src="image"
            />
          </a>
        </CCol>
        <CCol :xs="12" :lg="7">
          <CRow class="mb-2">
            <CCol> Uniek Nummer </CCol>
            <CCol>
              {{ werkelijkeTool?.unieknummer }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Volgnummer </CCol>
            <CCol>
              {{ werkelijkeTool?.volgnummer }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Machine Groep </CCol>
            <CCol>
              {{ werkelijkeTool?.tool?.machineGroep?.naam }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Locatie </CCol>
            <CCol>
              {{ werkelijkeTool?.locatie?.code }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Cam Toolnummer </CCol>
            <CCol>
              {{ werkelijkeTool?.tool?.camToolnummer }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Z Waarde </CCol>
            <CCol>
              {{ werkelijkeTool?.zWaarde }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> X Waarde </CCol>
            <CCol>
              {{ werkelijkeTool?.xWaarde }}
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol> Toolgroep </CCol>
            <CCol>
              {{ werkelijkeTool?.tool?.toolGroep }}
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CModalTitle>{{ 'Componenten' }}</CModalTitle>
        <CCol class="mt-2" :xs="12" :lg="5">
          <CImage
            @click.stop.prevent="displayImage(componentImage)"
            v-if="componentImage"
            style="object-fit: contain"
            rounded
            class="border border-light"
            height="300"
            width="300"
            :src="componentImage"
          />
        </CCol>
        <CCol>
          <CRow v-for="twoComponents in splitComponents" :key="twoComponents">
            <CCol
              @click="showComponentImage(toolComponent.componentId)"
              v-for="toolComponent in twoComponents"
              :key="toolComponent?.id"
              :class="calculateComponentClass(toolComponent)"
            >
              <CRow v-if="toolComponent">
                <CCol> Code </CCol>
                <CCol>
                  {{ toolComponent.component.code }}
                </CCol>
              </CRow>
              <CRow v-if="toolComponent">
                <CCol> Locatie </CCol>
                <CCol>
                  {{ toolComponent.component.locatie }}
                </CCol>
              </CRow>
              <CRow v-if="toolComponent">
                <CCol> Aantal </CCol>
                <CCol>
                  {{ toolComponent.aantal }}
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script>
import { defineComponent, toRefs, watch, ref, computed } from 'vue';
import DownloadClient from '../services/DownloadClient';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';

export default defineComponent({
  name: 'ToolViewerModal',
  components: {
    CIcon
  },
  props: {
    werkelijkeTool: {
      required: true
    },
    isModalActive: {
      required: false
    }
  },
  setup(props) {
    const { werkelijkeTool: werkelijkeTool, isModalActive: isModalActive } = toRefs(props);

    const image = ref(null);
    const componentImage = ref(null);
    const componentActiveId = ref(-1);
    const imageActive = ref(null);
    const imageIsActive = ref(false);

    const splitComponents = computed(() => {
      let temp = [];
      if (!werkelijkeTool.value?.tool?.toolComponents) return temp;
      let toolComponents = werkelijkeTool.value.tool.toolComponents;
      for (let i = 0; i < toolComponents.length; i += 2) {
        const twoComponents = [toolComponents[i], toolComponents[i + 1]];
        temp.push(twoComponents);
      }
      return temp;
    });

    function calculateComponentClass(toolComponent) {
      if (!toolComponent) {
        return 'm-2 p-2 border border-white';
      } else if (componentActiveId.value == toolComponent.componentId) {
        return 'm-2 p-2 text-light bg-success border border-success rounded';
      }
      return 'bg-light m-2 p-2 border rounded';
    }

    watch(
      werkelijkeTool,
      () => {
        image.value = null;
        componentImage.value = null;
        if (werkelijkeTool.value && werkelijkeTool.value.tool?.files?.length) {
          DownloadClient()
            .get(`files?toolId=${werkelijkeTool.value.tool.id}&resource=${werkelijkeTool.value.tool.files[0].resourceName}`, {
              responseType: 'arraybuffer'
            })
            .then((response) => {
              image.value = 'data:image/.jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');
            })
            .catch((error) => {
              throw error;
            });
        }
      },
      { immediate: true }
    );

    watch(isModalActive, () => {
      if (!isModalActive.value) {
        imageIsActive.value = false;
      }
    });

    function showComponentImage(componentId) {
      componentActiveId.value = componentId;
      DownloadClient()
        .get(`files?componentId=${componentId}`, { responseType: 'arraybuffer' })
        .then((response) => {
          componentImage.value = 'data:image/.jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');
        })
        .catch(() => {
          componentImage.value = null;
        });
    }

    function displayImage(image) {
      imageIsActive.value = true;
      imageActive.value = image;
    }

    return {
      image,
      componentImage,
      splitComponents,
      showComponentImage,
      calculateComponentClass,
      imageActive,
      imageIsActive,
      displayImage,
      cilArrowLeft
    };
  }
});
</script>