import CrudService from '../../services/CrudService';
import WissellijstService from '../../services/WissellijstService';

const namespaced = true;

const state = {
  wissellijstRegelsMetadata: [],
  wissellijstRegelsData: {},
  wissellijstRegelId: -1,
  wissellijstStatus: '',
  werkelijkeToolIn: {},
  oldData: [],
  oldWissellijstId: -1,
  machineName: ''
};

const mutations = {
  SET_WISSELLIJST_REGELS_METADATA(state, payload) {
    payload.properties.push({
      variableName: 'printLabel',
      displayName: 'Print',
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: 'werkelijkeToolOut',
          notEmpty: true
        }
      ]
    });
    state.wissellijstRegelsMetadata = payload;
  },

  SET_WISSELLIJST_REGELS_DATA(state, payload) {
    state.wissellijstRegelsData = payload;
  },

  SET_SELECTED_WISSELLIJST_REGEL_ID(state, payload) {
    state.wissellijstRegelId = payload;
  },

  SET_WISSELLIJST_STATUS(state, payload) {
    state.wissellijstStatus = payload;
  },

  SET_WERKELIJKE_TOOL_IN(state, payload) {
    state.werkelijkeToolIn = payload;
  },

  SET_OLD_DATA(state, payload) {
    state.oldData = payload;
  },

  SET_OLD_WISSELLIJST_ID(state, wissellijstId) {
    state.oldWissellijstId = wissellijstId;
  },

  SET_MACHINE_NAME(state, machineName) {
    state.machineName = machineName;
  }
};

const actions = {
  getWissellijstRegelsMetadata({ commit }) {
    CrudService.getMetadataByName('wissellijstregels', 'grid')
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) =>
            p.variableName != 'sequentialIdInToollijst' && p.variableName != 'werkelijkeToolAanwezig' && p.variableName != 'werkelijkeToolBeschikbaar'
        );
        response.data.properties.push({
          variableName: 'locatie',
          displayName: 'Locatie Uit',
          displayType: 11,
          pinned: true
        });

        commit('SET_WISSELLIJST_REGELS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getWissellijstRegelsDataById({ commit }, { wissellijstId }) {
    WissellijstService.get(wissellijstId)
      .then(async (response) => {
        const availableLocations = await CrudService.getGridData(`locaties/available?wissellijstId=${wissellijstId}`);
        if (!availableLocations.data.success) {
          commit('HANDLE_RESPONSE', { response: availableLocations.data }, { root: true });
          return;
        }

        const shouldPropose = await CrudService.getGridData('locaties/shouldPropose');

        response.data.value.wissellijst.regels.forEach((regel) => {
          if (regel.werkelijkeToolOut) {
            regel.dropdownConfiguration = {
              options: [{ code: 'Uw Keuze', locatie: -1 }].concat(availableLocations.data.value.locaties),
              class: 'form-select form-select-sm mb-3',
              defaultOption: 'Uw Keuze',
              isDefaultOptionDisabled: false,
              valuePropertyName: 'locatie',
              label: 'code',
              machineProposals: shouldPropose.data === true ? availableLocations.data.value.machineProposals : [],
              machineGroepProposals: shouldPropose.data === true ? availableLocations.data.value.machineGroepProposals : []
            };
          }
        });
        commit('SET_MACHINE_NAME', response.data.value.wissellijst.machine.naam);
        commit('SET_WISSELLIJST_REGELS_DATA', response.data.value.wissellijst.regels);
        commit('SET_WISSELLIJST_STATUS', response.data.value.wissellijst.status);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  updateWissellijstRegels({ commit }, regels) {
    commit('SET_LOADING_STATUS', true, { root: true });
    WissellijstService.update(regels)
      .then((response) => {
        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('ADD_TOAST_ERROR', error, { root: true });
        throw error;
      })
      .finally(() => commit('SET_LOADING_STATUS', false, { root: true }));
  }
};

const tabletmachinewissellijst = {
  state,
  mutations,
  actions,
  namespaced
};

export default tabletmachinewissellijst;
