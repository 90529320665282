import CrudService from '../../services/CrudService';
import ToollijstService from '../../services/ToollijstService';

const namespaced = true;

const state = {
  toollijstToolsMetadata: [],
  toollijstToolsData: {},
  selectedToolId: -1,
  werkelijkeTool: {},
  machineNaam: undefined,
  toollijstNaam: undefined
};

const mutations = {
  SET_TOOLLIJST_TOOLS_METADATA(state, payload) {
    state.toollijstToolsMetadata = payload;
  },

  SET_TOOLLIJST_TOOLS_DATA(state, payload) {
    state.toollijstToolsData = payload;
  },

  SET_SELECTED_TOOL_ID(state, payload) {
    state.selectedToolId = payload;
  },

  SET_WERKELIJKE_TOOL(state, payload) {
    state.werkelijkeTool = payload;
  },

  SET_TOOLLIJST_NAAM(state, payload) {
    state.toollijstNaam = payload;
  },

  SET_MACHINE_NAAM(state, payload) {
    state.machineNaam = payload;
  }
};

const actions = {
  getToolsMetadata({ commit }) {
    CrudService.getMetadataByName('toollijsttools', 'grid')
      .then((response) => {
        response.data.properties = response.data.properties
          .filter(
            (p) =>
              p.variableName == 'camToolnummer' ||
              p.variableName == 'toolCode' ||
              p.variableName == 'omschrijving' ||
              p.variableName == 'opmerking' ||
              p.variableName == 'standaardTool' ||
              p.variableName == 'tmId' ||
              p.variableName == 'volgnummer'
          )
          .map((x) => {
            x.displayPropertiesPath = undefined;
            x.displayProperties = undefined;
            return x;
          });
        console.log(response.data);
        commit('SET_TOOLLIJST_TOOLS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getToolsData({ commit }, toolId) {
    ToollijstService.getWerkvloerToollijst(toolId)
      .then((response) => {
        console.log(response.data.value);
        if (response.data.success) {
          commit('SET_TOOLLIJST_NAAM', response.data.value.toollijstNaam);
          commit('SET_MACHINE_NAAM', response.data.value.machineNaam);
          commit('SET_TOOLLIJST_TOOLS_DATA', response.data.value.tools);
        }

        commit('HANDLE_RESPONSE', { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const tablettoollijsttools = {
  state,
  mutations,
  actions,
  namespaced
};

export default tablettoollijsttools;
