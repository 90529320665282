<template>
  <CRow class="mb-3">
    <CCol>
      <CCard>
        <CCardHeader>
          <select
            class="form-select form-select-sm"
            @change="onMachineChange"
            v-model="store.state.toollijstenview.machineSelectedId"
            size="sm"
            style="width: 40%; margin-left: 30%; margin-right: 20%"
          >
            <option value="-1" selected>Kies een machine</option>
            <option v-for="machine in machines" :value="machine.id" :key="machine.id">{{ machine.naam }}</option>
          </select>
        </CCardHeader>
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol :xs="12" :lg="6">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Toollijsten</strong>
        </CCardHeader>
        <CCardBody>
          <CButton v-if="hasWriteRole" @click="onAdd()" color="primary" class="btn-sm mb-3 me-2"> Toevoegen </CButton>
          <CButton v-if="hasWriteRole" @click="onEdit" :disabled="toollijstSelectedRow?.id === undefined" color="light" class="btn-sm mb-3 me-2">
            Wijzigen
          </CButton>
          &nbsp;
          <CButton @click="onAdd(toollijstSelectedRow)" :disabled="toollijstSelectedRow?.id === undefined" color="light" class="btn-sm mb-3">
            Dupliceer
          </CButton>
          &nbsp;
          <span
            v-if="hasWriteRole"
            class="me-2"
            :title="
              toollijstSelectedRow?.id === undefined
                ? 'Er is geen toollijst geselecteerd'
                : doesMachineHaveWissellijst
                ? 'De machine heeft al een wissellijst die nog niet is afgerond'
                : isToollijstAlreadyInMachine
                ? 'Deze toollijst zit al in de machine'
                : machineHasVoorinstelOrderRegelsFromWissellijst
                ? 'De machine heeft voorinstelorder(regel)s open die eerst afgerond dienen te worden'
                : toolmagazijnCheckIsExpired
                ? 'Het toolmagazijn van deze machine moet opnieuw worden ingelezen'
                : undefined
            "
          >
            <CButton
              v-if="hasWriteRole"
              @click="onAddToMachine"
              :disabled="
                toollijstSelectedRow?.id === undefined ||
                doesMachineHaveWissellijst ||
                isToollijstAlreadyInMachine ||
                machineHasVoorinstelOrderRegelsFromWissellijst ||
                toolmagazijnCheckIsExpired
              "
              color="light"
              class="btn-sm mb-3 me-2"
            >
              Toevoegen aan machine
            </CButton>
          </span>
          <CButton @click="exportPdf" :disabled="toollijstSelectedRow?.id === undefined" color="light" class="btn-sm mb-3 me-2"> PDF </CButton>
          <CButton
            v-if="hasWriteRole"
            @click="
              () => {
                deleteModalActive = true;
              }
            "
            :disabled="toollijstSelectedRow?.id === undefined"
            color="danger"
            class="btn-sm mb-3"
            style="float: right"
          >
            Verwijderen
          </CButton>
          &nbsp;
          <dynamic-grid
            @data-changed="onToollijstenDataChanged"
            @double-clicked="onEdit"
            name="TLToollijsten"
            :data="toollijstenAvailableToMachine"
            :metadata="toollijstenMetadata"
            :autoPagination="true"
          />
          <h6 class="mt-3">Tools</h6>
          <dynamic-grid name="TLTools" :data="toolsData" :metadata="toolsMetadata" :autoPagination="true" />
          <h6 class="mt-3">Componenten</h6>
          <dynamic-grid name="TLComponenten" :data="componentenData" :metadata="componentenMetadata" :autoPagination="true" />
        </CCardBody>
      </CCard>
    </CCol>

    <CCol :xs="12" :lg="6">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Magazijnen</strong>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid name="TLMagazijnen" :metadata="magazijnenMetadata" :data="magazijnenSelected" :autoPagination="true" />
          <CRow>
            <CCol style="height: 70%" :xs="12" :lg="6">
              <h6 class="mt-3">Toollijsten in machine</h6>
              <CButton v-if="hasWriteRole" @click="exportToollijstNc" :disabled="isExportNcDisabled" color="light" class="btn-sm mb-2">
                Export NC
              </CButton>
              <dynamic-grid
                name="TLToollijstenInMachine"
                :metadata="machineToollijstenMetadata"
                :data="machineToollijstenData"
                :autoPagination="true"
              />
            </CCol>
            <CCol style="height: 70%" :xs="12" :lg="6">
              <h6 class="mt-3" :style="hasWriteRole ? 'margin-bottom: 47px' : ''">Toollijsten per tool</h6>
              <dynamic-grid
                :style="hasWriteRole ? 'margin-top: 10% !important' : ''"
                name="TLToollijstenPerTool"
                :metadata="toollijstenPerToolMetadata"
                :data="toollijstenPerToolData"
                :autoPagination="true"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <confirmation-modal
    :style="deleteModalActive ? undefined : 'display:none'"
    :modalActive="deleteModalActive"
    modalBody="Weet u zeker dat u de geselecteerde toollijst wilt verwijderen?"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { computed, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import DownloadClient from '@/services/DownloadClient';

export default {
  name: 'ToollijstenView',
  components: { DynamicGrid, ConfirmationModal },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const toollijstenMetadata = computed(() => store.state.toollijstenview.toollijstenMetadata);

    const toollijstenAvailableToMachine = computed(() => store.state.toollijstenview.toollijstenAvailableToMachine);
    const toollijstenData = computed(() => store.state.toollijstenview.toollijstenData);

    const componentenMetadata = computed(() => store.state.toollijstenview.componentenMetadata);
    const componentenData = computed(() => store.state.toollijstenview.componentenData);

    const toolsMetadata = computed(() => store.state.toollijstenview.toolsMetadata);
    const toolsData = computed(() => store.state.toollijstenview.toolsData);

    const magazijnenMetadata = computed(() => store.state.toollijstenview.magazijnenMetadata);
    const magazijnenSelected = computed(() => store.state.toollijstenview.magazijnenData);

    const machineSelectedId = computed(() => store.state.toollijstenview.machineSelectedId);

    const deleteModalActive = ref(false);

    const machines = computed(() => store.state.toollijstenview.machines);

    const machineToollijstenMetadata = computed(() => store.state.toollijstenview.toollijstenWithoutMachineMetadata);
    const machineToollijstenData = computed(() => store.state.toollijstenview.machineToollijstenData);

    const toollijstenPerToolMetadata = computed(() => store.state.toollijstenview.toollijstenWithoutMachineMetadata);
    const toollijstenPerToolData = computed(() => store.state.toollijstenview.toollijstenPerToolData);
    const toollijstSelectedRow = computed(() => {
      if (store.state.dynamicgrid['TLToollijsten'].selectedRows.length > 0) {
        return store.state.dynamicgrid['TLToollijsten'].selectedRows[0];
      }
      return undefined;
    });

    const doesMachineHaveWissellijst = computed(() => {
      return machines.value.filter((x) => x.id == toollijstSelectedRow.value.machine.id)[0].wissellijsten.filter((x) => x.status != 2).length > 0;
    });

    const toolmagazijnCheckIsExpired = computed(() => {
      const machine = machines.value.filter((m) => m.id == toollijstSelectedRow.value?.machine.id)[0];

      if (machine && machine.toolmagazijnImportProcedure) {
        if (!machine.leesdatum) {
          return true;
        }

        const date = new Date(machine.leesdatum);
        const now = new Date();
        now.setHours(now.getHours() - 4);

        if (date < now) {
          return true;
        }
      }

      return false;
    });

    const isToollijstAlreadyInMachine = computed(() => {
      const machine = machines.value.filter((m) => m.id == toollijstSelectedRow.value?.machine.id)[0];

      if (machine?.machineToollijsten?.length) {
        return machine.machineToollijsten.some((mt) => mt.toollijstId == toollijstSelectedRow.value?.id);
      }

      return false;
    });

    const machineHasVoorinstelOrderRegelsFromWissellijst = computed(() => store.state.toollijstenview.machineHasVoorinstelOrderRegelsFromWissellijst);

    const toolSelectedRow = computed(() => {
      if (store.state.dynamicgrid['TLTools'].selectedRows.length > 0) {
        return store.state.dynamicgrid['TLTools'].selectedRows[0];
      }
      return undefined;
    });

    const magazijnenSelectedRow = computed(() => {
      if (store.state.dynamicgrid['TLMagazijnen'].selectedRows.length > 0) {
        return store.state.dynamicgrid['TLMagazijnen'].selectedRows[0];
      }
      return undefined;
    });

    const toollijstenInMachineSelectedRow = computed(() => {
      if (store.state.dynamicgrid['TLToollijstenInMachine'].selectedRows.length > 0) {
        return store.state.dynamicgrid['TLToollijstenInMachine'].selectedRows[0];
      }
      return undefined;
    });

    const isExportNcDisabled = computed(() => {
      return (
        store.state.toollijstenview.machineSelectedId === undefined ||
        store.state.toollijstenview.machineSelectedId == -1 ||
        !toollijstenInMachineSelectedRow.value
      );
    });

    const hasWriteRole = computed(() => store.state.user?.roles?.includes('toollijsten') ?? false);

    store.commit('SET_LAST_PAGE', { name: 'toollijsten' }, { root: true });
    if (!store.state.toollijstenview.machines.length) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLToollijsten');
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLComponenten');
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLTools');
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLMagazijnen');
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLToollijstenInMachine');
      store.commit('dynamicgrid/SET_NEW_GRID', 'TLToollijstenPerTool');
      store.dispatch('toollijstenview/getToollijstenMetadata');
      store.dispatch('toollijstenview/getToollijstenData');
      store.dispatch('toollijstenview/getToolsMetadata');
      store.dispatch('toollijstenview/getComponentenMetadata');
      store.dispatch('toollijstenview/getMagazijnenMetadata');
      store.dispatch('toollijstenview/getMachines');
    } else {
      store.dispatch('toollijstenview/getMachines');
      if (machineSelectedId.value != -1) {
        onMachineChange(true);
      }
      if (toollijstSelectedRow.value) {
        store.dispatch('toollijstenview/checkIfMachineHasAnyVoorinstelOrderRegelFromWissellijst', toollijstSelectedRow.value.machine.id);
        store.dispatch('toollijstenview/getToollijstTools', toollijstSelectedRow.value.id);
      }
      if (toolSelectedRow.value) {
        store.dispatch('toollijstenview/getComponents', toolSelectedRow.value.toolId);
      }
    }

    watch(toollijstSelectedRow, () => {
      if (toollijstSelectedRow.value) {
        store.dispatch('toollijstenview/checkIfMachineHasAnyVoorinstelOrderRegelFromWissellijst', toollijstSelectedRow.value.machine.id);
        store.dispatch('toollijstenview/getToollijstTools', toollijstSelectedRow.value.id);
      } else {
        store.commit('toollijstenview/SET_TOOLS_DATA', []);
        store.commit('toollijstenview/SET_COMPONENTEN_DATA', []);
      }
    });

    watch(toolSelectedRow, () => {
      if (toolSelectedRow.value) {
        store.dispatch('toollijstenview/getComponents', toolSelectedRow.value.toolId);
      }
    });

    watch(magazijnenSelectedRow, () => {
      if (magazijnenSelectedRow.value) {
        store.dispatch('toollijstenview/getMachineToollijstPerTool', {
          tmId: magazijnenSelectedRow.value.tmId,
          machineId: magazijnenSelectedRow.value.machineId
        });
      }
    });

    function onAdd(row) {
      if (row) {
        router.push({
          name: 'toollijstencrud',
          params: {
            name: 'toollijsten',
            machineId: machineSelectedId.value && machineSelectedId.value != -1 ? machineSelectedId.value : row.machine.id,
            naam: row.toollijstNaam + ' - Kopie',
            klant: row.klant,
            tekeningnummer: row.tekeningnummer,
            revisienummer: row.revisienummer,
            benaming: row.benaming,
            aanmaakdatum: row.aanmaakdatum,
            inleesprocedure: row.inleesProcedure.id,
            directory: row.directory,
            bronbestand: row.bronBestand,
            doelbestand: row.doelBestand
          }
        });
      } else {
        router.push({
          name: 'toollijstencrud',
          params: {
            name: 'toollijsten',
            machineId: machineSelectedId.value && machineSelectedId.value != -1 ? machineSelectedId.value : undefined
          }
        });
      }
    }

    function onEdit() {
      if (hasWriteRole.value) {
        router.push({
          name: 'toollijstencrud',
          params: {
            name: 'toollijsten',
            id: toollijstSelectedRow.value.id
          }
        });
      }
    }

    function onDelete() {
      store.dispatch('toollijstenview/deleteToollijst', toollijstSelectedRow.value.id);
      store.state.dynamicgrid['TLToollijsten'].selectedRow = {};
      deleteModalActive.value = false;
    }

    function onAddToMachine() {
      router.push({
        name: 'wissellijsten',
        params: {
          toollijstId: toollijstSelectedRow.value.id,
          machineId: machines.value.filter((x) => x.naam == toollijstSelectedRow.value.machineId)[0].id
        }
      });
    }

    function onMachineChange(onlyDoNetworkCall = false) {
      if (!onlyDoNetworkCall) {
        store.commit('toollijstenview/SET_TOOLS_DATA', []);
        store.commit('toollijstenview/SET_COMPONENTEN_DATA', []);
      }

      if (machineSelectedId.value == '-1' && onlyDoNetworkCall !== true) {
        store.commit('toollijstenview/SET_MACHINE_TOOLLIJSTEN_DATA', []);
        store.commit('toollijstenview/SET_MAGAZIJNEN_DATA', []);
        store.commit('toollijstenview/SET_TOOLLIJSTEN_AVAILABLE_TO_MACHINE', toollijstenData.value);
      } else if (machineSelectedId.value != '-1') {
        store.dispatch('toollijstenview/getMagazijnen', machineSelectedId.value);
        store.dispatch('toollijstenview/getMachineLijsten', machineSelectedId.value);
        const actualMachineSelected = machines.value.filter((machine) => machine.id == machineSelectedId.value)[0];
        store.commit(
          'toollijstenview/SET_TOOLLIJSTEN_AVAILABLE_TO_MACHINE',
          toollijstenData.value.filter((tl) => tl.machineId == actualMachineSelected.id)
        );
      }

      if (!onlyDoNetworkCall) {
        store.commit('dynamicgrid/SET_SELECTED_ROWS', { payload: [], identifier: 'TLToollijstenInMachine' });
      }
    }

    function exportToollijstNc() {
      store.dispatch('toollijstenview/exportToollijstNc', toollijstenInMachineSelectedRow.value.id);
    }

    const toollijstenGridApi = ref(undefined);
    function onToollijstenDataChanged(params) {
      toollijstenGridApi.value = params;
    }

    watch(toollijstenInMachineSelectedRow, () => {
      if (toollijstenInMachineSelectedRow.value) {
        setTimeout(() => {
          toollijstenGridApi.value.api.forEachNode((node) => {
            node.setSelected(node.data.id === toollijstenInMachineSelectedRow.value.id);
          });
        }, 0);
      }
    });

    function exportPdf() {
      DownloadClient()
        .get(`toollijsten/download/${toollijstSelectedRow.value.id}`, { responseType: 'arraybuffer' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Toollijst ${toollijstSelectedRow.value.toollijstNaam}`);
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit('ADD_TOAST_SUCCESS', 'De toollijst is opgeslagen als pdf.', { root: true });
        })
        .catch(() => store.commit('ADD_TOAST_ERROR', 'De toollijst kon niet worden geëxporteerd.', { root: true }));
    }

    return {
      toollijstenMetadata,
      componentenMetadata,
      componentenData,
      magazijnenMetadata,
      magazijnenSelected,
      toolsData,
      toolsMetadata,
      machineSelectedId,
      machines,
      onAdd,
      onEdit,
      onMachineChange,
      onAddToMachine,
      onDelete,
      deleteModalActive,
      toollijstSelectedRow,
      toolSelectedRow,
      machineToollijstenMetadata,
      machineToollijstenData,
      magazijnenSelectedRow,
      toollijstenPerToolMetadata,
      toollijstenPerToolData,
      toollijstenAvailableToMachine,
      store,
      doesMachineHaveWissellijst,
      toolmagazijnCheckIsExpired,
      exportPdf,
      exportToollijstNc,
      isExportNcDisabled,
      isToollijstAlreadyInMachine,
      onToollijstenDataChanged,
      machineHasVoorinstelOrderRegelsFromWissellijst,
      hasWriteRole
    };
  }
};
</script>