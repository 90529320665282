<template>
  <CRow>
    <CCol :xs="12" :lg="6">
      <CCard>
        <CCardHeader>
          <strong>Wissellijst - {{ machine.naam }}</strong>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol :xs="12" :lg="6">
              <h6>Toollijst Toevoegen</h6>
              <CFormInput type="text" :value="toollijstName" readonly />
            </CCol>
            <CCol :xs="12" :lg="6">
              <h6>Toollijsten in machine</h6>
              <select size="5" v-model="selectedMachineToollijst" class="form-control">
                <option
                  v-for="machineToollijst in machine.machineToollijsten?.filter((x) => !toollijstenToRemove.map((x) => x.id).includes(x.id))"
                  :key="machineToollijst.id"
                  :value="machineToollijst.id"
                >
                  {{ machineToollijst.toollijst.toollijstNaam }}
                </option>
              </select>
            </CCol>
          </CRow>
          <CRow class="mt-5">
            <CCol :xs="12" :lg="6">
              <CButton
                @click="onAddToollijstenToAdd"
                :disabled="!!toollijstenToAdd.filter((t) => t.id == toollijstId).length"
                color="primary"
                class="btn-sm mb-2"
              >
                Toevoegen
              </CButton>
              &nbsp;
              <CButton @click="onRemoveToollijstenToAdd" :disabled="!toollijstenToAdd.length" color="primary" class="btn-sm mb-2">
                Verwijderen
              </CButton>
              &nbsp;
              <select v-model="selectedToollijstToAdd" size="5" class="form-control">
                <option v-for="toollijstToAdd in toollijstenToAdd" :key="toollijstToAdd.id" :value="toollijstToAdd.id">
                  {{ toollijstToAdd.toollijstNaam }}
                </option>
              </select>
            </CCol>
            <CCol :xs="12" :lg="6">
              <CButton
                @click="onAddToollijstenToRemove"
                :disabled="!machine.machineToollijsten?.length || !selectedMachineToollijst"
                color="primary"
                class="btn-sm mb-2"
              >
                Uit
              </CButton>
              &nbsp;
              <CButton
                @click="onRemoveToollijstenToRemove"
                :disabled="!toollijstenToRemove?.length || !selectedToollijstToRemove"
                color="primary"
                class="btn-sm mb-2"
              >
                Terug
              </CButton>
              &nbsp;
              <select v-model="selectedToollijstToRemove" size="5" class="form-control">
                <option v-for="toollijstToRemove in toollijstenToRemove" :key="toollijstToRemove.id" :value="toollijstToRemove.id">
                  {{ toollijstToRemove.toollijst.toollijstNaam }}
                </option>
              </select>
              &nbsp;
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol :xs="12" :lg="6">
              <CButton @click="getToolsInOut" :disabled="undefined" color="primary" class="btn-md mb-2"> Bepaal tools in/uit </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol :xs="12" :lg="6" class="mb-5">
              <h6>Tools IN: {{ toolsInData.length }}</h6>
              <dynamic-grid name="WLToolsIn" :metadata="toolsInMetadata" :data="toolsInData" :autoPagination="true" :maxColumnsBeforeOverflow="0" />
            </CCol>
            <CCol :xs="12" :lg="6" class="mb-5">
              <h6>Tools UIT: {{ toolsOutData.length }}, VRIJ: {{ spacesLeft }}</h6>
              <dynamic-grid
                name="WLToolsOut"
                :metadata="toolsOutMetadata"
                :data="toolsOutData"
                :autoPagination="true"
                :maxColumnsBeforeOverflow="0"
              />
            </CCol>
          </CRow>
          <CRow>
            <h6>Meldingen:</h6>
            <h7 v-for="comment in inOutComments" :key="comment">{{ comment }}</h7>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12" :lg="6">
      <CCard>
        <CCardHeader>
          <strong>Wissellijst</strong>
        </CCardHeader>
        <CCardBody>
          <CRow class="mb-2">
            <CCol v-for="(config, index) in buttonConfigs" :key="index" style="max-width: 350px !important" class="float-start">
              <CButton
                @click="config.click"
                :disabled="(toolsOutData?.length ?? 0) + (spacesLeft ?? 0) < toolsInData.length"
                :color="config.color"
                class="btn-md"
              >
                {{ config.text }}
              </CButton>
            </CCol>
          </CRow>

          <CRow>
            <dynamic-grid
              @data-changed="onWissellijstGridUpdated"
              name="WLWissellijst"
              :metadata="wissellijstMetadata"
              :data="wissellijstData"
              :autoPagination="true"
              :maxColumnsBeforeOverflow="0"
              :multiSelect="true"
            />
          </CRow>
          <CButton @click="saveWissellijst" :disabled="isSaveEnabled !== true" color="primary" class="btn-md mt-3 mb-1">
            Wissellijst opslaan
          </CButton>
          &nbsp;
          <CRow class="mt-2">
            <h6>Meldingen:</h6>
            <h7 v-for="comment in wissellijstComments" :key="comment">{{ comment }}</h7>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <confirmation-modal
    :style="confirmModalActive ? undefined : 'display:none'"
    size="xl"
    :modalActive="confirmModalActive"
    :modalBody="modalBody"
    @on-continue="onConfirm"
    @on-cancel="onCancel"
  />
</template>























<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, ref, toRefs, watch } from 'vue';
import { move } from '@/helpers/gridHelper.js';
import WissellijstService from '../../services/WissellijstService';
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  name: 'WissellijstenCrud',
  components: { DynamicGrid, ConfirmationModal },
  props: {
    machineId: {
      required: true
    },
    toollijstId: {
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { toollijstId: toollijstId, machineId: machineId } = toRefs(props);

    if (!store.state.wissellijsten[machineId.value]) {
      // Also creates a new one - not just for clearing ;)
      store.commit('wissellijsten/CLEAR_HISTORY', machineId.value);
    }
    store.dispatch('wissellijsten/getMachineById', { id: machineId.value, identifier: machineId.value });
    store.dispatch('wissellijsten/getToolsInMetadata');
    store.dispatch('wissellijsten/getToolsOutMetadata');
    store.dispatch('wissellijsten/getWissellijstMetadata');

    store.commit('dynamicgrid/SET_NEW_GRID', 'WLToolsIn');
    store.commit('dynamicgrid/SET_NEW_GRID', 'WLToolsOut');
    store.commit('dynamicgrid/SET_NEW_GRID', 'WLWissellijst');

    const toollijst = computed(() => store.state.wissellijsten[machineId.value].toollijst);
    const machine = computed(() => store.state.wissellijsten[machineId.value].machine);
    const warningsToConfirm = computed(() => store.state.wissellijsten[machineId.value].warningsToConfirm);
    const defaultToPresetEverything = computed(() => store.state.defaultToPresetEverything);
    const buttonConfigs = computed(() => {
      if (defaultToPresetEverything.value) {
        return [
          { click: () => createWissellijst(true), text: 'Wissellijst maken en alles voorinstellen', color: 'primary' },
          { click: createWissellijst, text: 'Wissellijst maken', color: 'secondary' }
        ];
      } else {
        return [
          { click: createWissellijst, text: 'Wissellijst maken', color: 'primary' },
          { click: () => createWissellijst(true), text: 'Wissellijst maken en alles voorinstellen', color: 'secondary' }
        ];
      }
    });

    const toollijstName = ref('');
    watch([toollijst, machineId], () => {
      if (machineId.value) {
        if (toollijst.value?.toollijstNaam) {
          toollijstName.value = toollijst.value.toollijstNaam;
          onAddToollijstenToAdd();
          if (toollijstenToAdd.value?.length) {
            store.commit('wissellijsten/SET_TOOLLIJSTEN_TO_ADD', {
              payload: toollijstenToAdd.value.filter((t) => t.machineId == machineId.value),
              identifier: machineId.value
            });
          }
        }
      }
    });

    watch(
      toollijstId,
      () => {
        if (toollijstId.value) {
          store.dispatch('wissellijsten/getToollijstById', { id: toollijstId.value, identifier: machineId.value });
        }
      },
      { immediate: true }
    );

    const confirmModalActive = ref(false);
    const modalBody = ref(null);
    watch(warningsToConfirm, () => {
      if (warningsToConfirm.value?.length) {
        modalBody.value =
          warningsToConfirm.value.join('\n\n') + "\n\n Klik op 'DOORGAAN' om alsnog voor in te stellen, of druk op 'ANNULEREN' om te stoppen.";
        confirmModalActive.value = true;
      }
    });

    function onConfirm() {
      confirmModalActive.value = false;
      store.commit('wissellijsten/SET_WARNINGS_TO_CONFIRM', { payload: [], identifier: machineId.value });
    }

    function onCancel() {
      store.commit('SET_ERROR', warningsToConfirm.value.join('\n'), { root: true });
      confirmModalActive.value = false;
      router.push({
        name: 'toollijsten'
      });
      store.state.wissellijsten[machineId.value].isSaveEnabled = false;
      store.commit('wissellijsten/SET_WARNINGS_TO_CONFIRM', { payload: [], identifier: machineId.value });
    }

    const toolsInMetadata = computed(() => store.state.wissellijsten.toolsInMetadata);
    const toolsInData = computed(() => store.state.wissellijsten[machineId.value].toolsInData);

    const toolsOutMetadata = computed(() => store.state.wissellijsten.toolsOutMetadata);
    const toolsOutData = computed(() => store.state.wissellijsten[machineId.value].toolsOutData);

    const spacesLeft = computed(() => store.state.wissellijsten[machineId.value].spacesLeft);

    const wissellijstMetadata = computed(() => store.state.wissellijsten.wissellijstMetadata);
    const wissellijstData = computed(() => store.state.wissellijsten[machineId.value].wissellijstData);

    const machineToollijstenData = computed(() => store.state.toollijstenview.machineToollijstenData);
    const toollijstenData = computed(() => store.state.toollijstenview.toollijstenData);

    const toollijstenToAdd = computed(() => store.state.wissellijsten[machineId.value].toollijstenToAdd);
    const toollijstenToRemove = computed(() => store.state.wissellijsten[machineId.value].toollijstenToRemove);

    const inOutComments = computed(() => store.state.wissellijsten[machineId.value].inOutComments);
    const wissellijstComments = computed(() => store.state.wissellijsten[machineId.value].wissellijstComments);

    const isSaveEnabled = computed(() => store.state.wissellijsten[machineId.value].isSaveEnabled);
    const wissellijstSaved = computed(() => store.state.wissellijsten[machineId.value].wissellijstSaved);

    const selectedToollijstToAdd = ref(undefined);
    const selectedToollijstToRemove = ref(undefined);
    const selectedMachineToollijst = ref(undefined);

    function onAddToollijstenToAdd() {
      if (toollijstenToAdd.value.filter((t) => t.id == toollijst.value.id).length < 1) {
        store.commit('wissellijsten/ADD_TOOLLIJST_TO_ADD', { payload: toollijst.value, identifier: machineId.value });
      }
    }

    function onRemoveToollijstenToAdd() {
      store.commit('wissellijsten/REMOVE_TOOLLIJST_TO_ADD', { toollijstId: selectedToollijstToAdd.value, identifier: machineId.value });
    }

    function onAddToollijstenToRemove() {
      move(
        selectedMachineToollijst.value,
        store.state.wissellijsten[machineId.value].machine.machineToollijsten,
        store.state.wissellijsten[machineId.value].toollijstenToRemove
      );
    }

    function onRemoveToollijstenToRemove() {
      if (selectedToollijstToRemove.value) {
        move(
          selectedToollijstToRemove.value,
          store.state.wissellijsten[machineId.value].toollijstenToRemove,
          store.state.wissellijsten[machineId.value].machine.machineToollijsten
        );
      }
    }

    function createRequest() {
      const out = toollijstenToRemove.value.map((t) => t.toollijst.id);
      return {
        machineId: machineId.value,
        toollijstIdsIn: toollijstenToAdd.value.map((t) => t.id),
        toollijstIdsOut: out,
        toollijstIdsUnchanged: machine.value.machineToollijsten.map((t) => t.toollijstId).filter((x) => !out.includes(x))
      };
    }

    function getToolsInOut() {
      const request = createRequest();
      request.identifier = machineId.value;
      store.dispatch('wissellijsten/getToolsInOut', request);
    }

    function onWissellijstGridUpdated(params) {
      params.api.forEachNode((node) => {
        console.log(node.data);
        node.setSelected(
          node.data.werkelijkeToolAanwezig == false || node.data.werkelijkeToolBeschikbaar == false || node.data.werkelijkeToolRequiresMeasurement
        );
      });
    }

    const wasPresetEverythingChosenLast = ref(false);
    function createWissellijst(presetEverything) {
      const request = createRequest();
      request.identifier = machineId.value;
      request.presetEverything = presetEverything == true;
      wasPresetEverythingChosenLast.value = presetEverything == true;
      store.dispatch('wissellijsten/createWissellijst', request);
    }

    function saveWissellijst() {
      store.commit('SET_LOADING_STATUS', true, { root: true });
      const request = createRequest();
      request.identifier = machineId.value;
      request.presetEverything = wasPresetEverythingChosenLast.value;
      WissellijstService.save(request)
        .then((response) => {
          if (response.data.success) {
            store.commit('ADD_TOAST_SUCCESS', 'De wissellijst is succesvol opgeslagen.', { root: true });

            if (response.data.value.wissellijst.status == 0) {
              router.push({
                name: 'voorinstelorders',
                params: {
                  machineId: machineId.value,
                  wissellijstSelectedRegels: response.data.value.voorinstelOrderRegels.map((x) => {
                    return x.id;
                  })
                }
              });
            } else {
              router.push({
                name: 'wissellijstenview'
              });
            }

            if (store.state.toollijstenview?.machines?.length) {
              const machine = store.state.toollijstenview.machines.filter((x) => x.id == machineId.value)[0];
              if (machine?.wissellijsten) {
                machine.wissellijsten.push(response.data.value.wissellijst);
              }
            }
            store.commit('wissellijsten/CLEAR_HISTORY', machineId.value);
          } else {
            // To Do: interpret property (this is not good)
            const message = response.data.messages[0].text;
            store.commit('ADD_TOAST_ERROR', message, { root: true });
          }
        })
        .catch((error) => {
          store.commit('ADD_TOAST_ERROR', error, { root: true });
        })
        .finally(() => store.commit('SET_LOADING_STATUS', false, { root: true }));
    }

    return {
      machineToollijstenData,
      machine,
      toollijst,
      toolsInMetadata,
      toolsInData,
      toolsOutMetadata,
      toolsOutData,
      wissellijstMetadata,
      wissellijstData,
      toollijstenData,
      toollijstenToAdd,
      toollijstenToRemove,
      onAddToollijstenToAdd,
      onAddToollijstenToRemove,
      onRemoveToollijstenToAdd,
      onRemoveToollijstenToRemove,
      selectedToollijstToAdd,
      selectedToollijstToRemove,
      selectedMachineToollijst,
      getToolsInOut,
      createWissellijst,
      inOutComments,
      wissellijstComments,
      saveWissellijst,
      spacesLeft,
      wissellijstSaved,
      toollijstName,
      isSaveEnabled,
      onWissellijstGridUpdated,
      confirmModalActive,
      modalBody,
      onConfirm,
      onCancel,
      defaultToPresetEverything,
      buttonConfigs
    };
  }
};
</script>






















