import CrudService from '../../services/CrudService';
import { loadOptionsAsync } from '../../helpers/apiHelper';

const namespaced = true;

const state = {
  ordersMetadata: {},
  opspanningenMetadata: {},
  scansMetadata: {},
  opspanningenFormMetadata: {}
};

const mutations = {
  SET_ORDERS_METADATA(state, payload) {
    state.ordersMetadata = payload;
  },

  SET_ORDERS_DATA(state, { payload, identifier }) {
    state[identifier].ordersData = payload;
  },

  SET_OPSPANNINGEN_METADATA(state, payload) {
    state.opspanningenMetadata = payload;
  },

  SET_OPSPANNINGEN_DATA(state, { payload, identifier }) {
    state[identifier].opspanningenData = payload;
  },

  SET_SCANS_METADATA(state, payload) {
    state.scansMetadata = payload;
  },

  SET_SCANS_DATA(state, { payload, identifier }) {
    state[identifier].scansData = payload;
  },

  SET_OPSPANNINGEN_FORM_METADATA(state, payload) {
    state.opspanningenFormMetadata = payload;
  },

  SET_NEW_ORDER_FORM(state, identifier) {
    state[identifier] = {
      ordersData: {},
      opspanningenData: [],
      scansData: []
    };
  }
};

const actions = {
  getOrdersMetadata({ commit }, isUpdate) {
    CrudService.getMetadataByName('orders', 'form')
      .then(async (response) => {
        response.data.properties = response.data.properties.filter((p) => p.editable || isUpdate === true || isUpdate == 'true');
        await loadOptionsAsync(response.data.properties, response.data.endpoint);
        commit('SET_ORDERS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getOpspanningenMetadata({ commit }) {
    CrudService.getMetadataByName('opspanningen', 'grid')
      .then((response) => {
        commit('SET_OPSPANNINGEN_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getScansMetadata({ commit }) {
    CrudService.getMetadataByName('scans', 'grid')
      .then((response) => {
        commit('SET_SCANS_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getOrderData({ commit }, { id, identifier }) {
    CrudService.getDataById('orders', id)
      .then((response) => {
        commit('SET_ORDERS_DATA', { payload: response.data.value, identifier });
        commit('SET_OPSPANNINGEN_DATA', { payload: response.data.value.opspanningen, identifier });
        commit('SET_SCANS_DATA', { payload: response.data.value.scans, identifier });
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  },

  getOpspanningenFormMetadata({ commit }, isUpdate) {
    CrudService.getMetadataByName('opspanningen', 'form')
      .then(async (response) => {
        response.data.properties = response.data.properties.filter((p) => p.editable || isUpdate === true || isUpdate == 'true');
        const orderId = response.data.properties.filter((p) => p.variableName == 'orderNummer' || p.variableName == 'orderId')[0];
        orderId.editable = false;

        await loadOptionsAsync(response.data.properties, response.data.endpoint);
        commit('SET_OPSPANNINGEN_FORM_METADATA', response.data);
      })
      .catch((error) => {
        commit('SET_ERROR', error, { root: true });
        throw error;
      });
  }
};

const orderscrud = {
  state,
  mutations,
  actions,
  namespaced
};

export default orderscrud;
