<template>
  <CCol>
    <CRow class="mb-3">
      <CCol>
        <label class="col-form-label">Afbeelding</label>
      </CCol>
      <CCol>
        <label for="upload" class="btn btn-primary btn-md float-end" style="display: block; width: 100%">Upload</label>
        <input id="upload" style="display: none" @change="onEventFilePicked" type="file" accept="image/*" ref="importFile" />
      </CCol>
    </CRow>
  </CCol>
  <CCol class="m-2">
    <a class="position-relative" v-if="image?.binary">
      <CButton class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-info" @click.prevent="downloadToDisk(image)">
        <CIcon :icon="cilCloudDownload" size="sm" />
      </CButton>
      <CButton class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" @click.prevent="deleteFile(image)">
        <CIcon :icon="cilTrash" size="sm" />
      </CButton>
      <CRow
        @click="
          () => {
            imageModalActive = !!image?.binary;
          }
        "
      >
        <CImage style="object-fit: contain" class="border border-light" height="150" :src="image.binary" rounded />
      </CRow>
      <CRow>
        <label v-if="image?.binary">{{ image.originalName?.substring(0, 20) ?? 'Geen bestandsnaam beschikbaar' }}</label>
      </CRow>
    </a>
  </CCol>
  <div :style="imageModalActive ? undefined : 'display:none'">
    <CModal
      size="md"
      :visible="imageModalActive"
      @close="
        () => {
          imageModalActive = false;
        }
      "
    >
      <CModalHeader>
        <CModalTitle>{{ image.binary ? image.originalName ?? 'Geen bestandsnaam beschikbaar' : 'Geen afbeelding beschikbaar' }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CImage v-if="image?.binary" style="object-fit: contain" rounded class="img-fluid border border-light" :src="image.binary" />
      </CModalBody>
    </CModal>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, watch } from 'vue';
import ApiClient from '../services/ApiClient';
import DownloadClient from '../services/DownloadClient';
import { CIcon } from '@coreui/icons-vue';
import { cilTrash, cilCloudDownload } from '@coreui/icons';
import { createUrl } from '@/helpers/apiHelper.js';

export default defineComponent({
  name: 'FormImageUpload',
  components: {
    CIcon
  },
  props: {
    modelValue: {
      required: false
    },
    baseUrl: {
      required: true
    },
    downloadConfig: {
      required: true
    },
    uploadConfig: {
      required: true
    },
    requiredVariable: {
      required: false
    },
    deleteConfig: {
      required: true
    }
  },
  emits: ['uploadFailed', 'downloadFailed', 'update:modelValue', 'deleteSucceeded', 'deleteFailed'],
  setup(props, { emit }) {
    const {
      modelValue: modelValue,
      downloadConfig: downloadConfig,
      uploadConfig: uploadConfig,
      requiredVariable: requiredVariable,
      deleteConfig: deleteConfig
    } = toRefs(props);
    const imageModalActive = ref(false);

    const image = ref({});
    const importFile = ref(null);

    watch(
      modelValue,
      () => {
        if (modelValue.value != image.value) {
          image.value = modelValue.value;
          if (modelValue.value) {
            download(image.value);
          }
        }
      },
      { immediate: true }
    );

    const onEventFilePicked = () => {
      let file = importFile.value.files[importFile.value.files.length - 1];
      if (!file.name) return;
      importFile.value.value = '';
      upload(file);
    };

    function upload(file) {
      const url = createUrl(uploadConfig.value, image.value, requiredVariable.value);

      const formData = new FormData();
      formData.append('file', file);
      ApiClient()
        .post(url, formData)
        .then((response) => {
          image.value = response.data;
          setImage(file);
          emit('update:modelValue', image.value);
        })
        .catch((error) => emit('uploadFailed', { model: file, error, url }));
    }

    function setImage(file) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        image.value.binary = fileReader.result;
        image.value.originalName = file.name;
        emit('update:modelValue', image.value);
      });
      fileReader.readAsDataURL(file);
    }

    function download() {
      const url = createUrl(downloadConfig.value, image.value, requiredVariable.value);
      DownloadClient()
        .get(url, { responseType: 'arraybuffer' })
        .then((response) => {
          image.value.binary = 'data:image/.jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');
          emit('update:modelValue', image.value);
        })
        .catch((error) => emit('downloadFailed', { url, error }));
    }

    function downloadToDisk(fileDetails) {
      const url = createUrl(downloadConfig.value, image.value, requiredVariable.value);
      DownloadClient()
        .get(url, { responseType: 'arraybuffer' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: fileDetails.contentType }));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileDetails.originalName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }

    function deleteFile(fileDetails) {
      const url = createUrl(deleteConfig.value, image.value, requiredVariable.value);
      ApiClient()
        .delete(url)
        .then((response) => {
          image.value = {};
          emit('deleteSucceeded', { fileDetails, response });
          emit('update:modelValue', undefined);
        })
        .catch((error) => emit('deleteFailed', { fileDetails, error }));
    }

    return {
      image,
      onEventFilePicked,
      imageModalActive,
      cilCloudDownload,
      cilTrash,
      downloadToDisk,
      deleteFile,
      importFile
    };
  }
});
</script>