export function getErrorMessage(property, value, id, fieldValues) {
  if (property.required && (value === undefined || value === '' || value === null)) {
    return 'Verplicht Veld';
  }
  if (value === undefined || value === null) return undefined;

  if (property.unique) {
    console.log(value);
    console.log(property);
    const duplicateValues = property.usedValues.filter((p) => p[property.variableName] == value);
    console.log(duplicateValues);
    if (property.uniqueCombinations) {
      for (let element of duplicateValues) {
        if (element?.id == id) break;
        let isDuplicated = true;
        property.uniqueCombinations.forEach((combi) => {
          if (element[combi] != fieldValues[combi]) {
            if (!element[combi] || !fieldValues[combi]) {
              isDuplicated = false;
            } else if (element[combi]['naam'] != fieldValues[combi]) {
              isDuplicated = false;
            }
          }
        });

        if (isDuplicated === true) {
          return 'Deze waarde bestaat al voor dit veld';
        }
      }
    } else {
      if (duplicateValues.length && duplicateValues[0].id != id) {
        return 'Deze waarde bestaat al voor dit veld';
      }
    }
  }

  if (property.minValue !== undefined && property.minValue !== null && parseFloat(value) < property.minValue) {
    return `Dit veld heeft een minimale waarde van ${property.minValue}`;
  }
  if (property.maxValue !== undefined && property.maxValue !== null && parseFloat(value) > property.maxValue) {
    return `Dit veld heeft een maximale waarde van ${property.maxValue}`;
  }
  if (property.regex !== undefined && property.regex !== null && new RegExp(property.regex).test(value) === false) {
    return `Dit veld moet voldoen aan de regex ${property.regex}`;
  }
  if (property.maxLength !== undefined && property.maxLength !== null && value.length > property.maxLength) {
    return `Dit veld heeft een maximaal toegestane lengte van ${property.maxLength}`;
  }
  if (property.minLength !== undefined && property.minValue !== null && value.length < property.minLength) {
    return `Dit veld heeft een minimaal toegestane lengte van ${property.minLength}`;
  }
}

export function getSuggestions(property, value) {
  if (value?.length ?? 0 > 3) {
    const results = property.usedValues
      .map((x) => x[property.variableName])
      .filter((v) => v != value && v.toString().substring(0, value.length) == value);
    return results.slice(0, 2);
  }
  return [];
}

export function getValidationError(property, validationErrors) {
  const error = validationErrors.find((error) => error.propertyName === property.variableName);
  if (error) {
    return error.error;
  }
  return '';
}
