<template>
  <CCard class="col-md-8">
    <CCardHeader>
      <strong> {{ metadata?.modelDisplayName }} </strong>
      <CButton @click="goToGrid" class="btn btn-primary btn-sm ms-2 float-end">Annuleren</CButton>
      <CButton :disabled="validationErrors.length" @click="onSubmit" class="btn btn-primary btn-sm float-end">Opslaan</CButton>
    </CCardHeader>
    <CCardBody>
      <dynamic-form :metadata="metadata" :data="fieldValues" :name="componentName" :isEdit="!!id" />
    </CCardBody>
  </CCard>
</template>

<script>
import DynamicForm from './DynamicForm.vue';
import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';
import CrudService from '../../services/CrudService';
import { useRouter } from 'vue-router';

export default {
  components: { DynamicForm },
  props: {
    name: {
      required: true
    },
    id: {
      required: false
    },
    storeName: {
      required: false
    },
    propertiesToUpdate: {
      required: false
    }
  },
  setup(props) {
    const { name: componentName, id: id, storeName: storeName, propertiesToUpdate: propertiesToUpdate } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    store.commit('dynamicform/SET_NEW_FORM', componentName.value);
    store.commit('dynamicformview/SET_NEW_FORM_VIEW', componentName.value);

    const metadata = computed(() => store.state.dynamicformview[componentName.value].metadata);
    const fieldValues = computed(() => store.state.dynamicformview[componentName.value].fieldValues);

    const customGridRoute = computed(() => store.state.dynamicformview[componentName.value].metadata.customGridRoute);

    const loadingStatus = computed(() => store.state.dynamicform[componentName.value].loadingStatus);
    const validationErrors = computed(() => store.state.dynamicform[componentName.value].validationErrors);

    const lastPage = computed(() => store.state.lastPage);
    store.dispatch('dynamicformview/getMetadata', { id: id.value, endpoint: componentName.value, identifier: componentName.value });
    if (id.value) {
      store.dispatch('dynamicformview/getFieldValues', { endpoint: componentName.value, id: id.value, identifier: componentName.value });
    }

    const onSubmit = async () => {
      store.commit('dynamicform/SET_LOADING_STATUS', { loadingStatus: true, identifier: componentName.value });
      const isUpdate = !!id.value;

      const updateOrAdd = () =>
        isUpdate
          ? CrudService.putData(componentName.value, id.value, store.state.dynamicform[componentName.value].fieldValues)
          : CrudService.postData(componentName.value, store.state.dynamicform[componentName.value].fieldValues);

      updateOrAdd()
        .then((response) => {
          if (response?.data?.success === false) {
            const errorMsg = response?.data?.messages?.filter((m) => m.messageType == 2)[0].text;
            store.commit('ADD_TOAST_ERROR', errorMsg ?? 'Uw wijzigingen konden niet worden opgeslagen.', { root: true });
          } else if (isUpdate) {
            if (storeName.value && propertiesToUpdate.value?.length) {
              propertiesToUpdate.value.forEach((propertyToUpdate) => {
                const index = store.state[storeName.value][propertyToUpdate].findIndex((x) => x.id == id.value);
                if (index >= 0) {
                  store.state[storeName.value][propertyToUpdate][index] = response.data?.value ?? fieldValues.value;
                }
              });
            }
            store.commit('ADD_TOAST_SUCCESS', 'Uw wijzigingen zijn opgeslagen.', { root: true });
            goToGrid();
          } else {
            fieldValues.value.id = response.data?.value?.id ?? response.data.id;
            if (storeName.value) {
              propertiesToUpdate.value.forEach((propertyToUpdate) => {
                store.state[storeName.value][propertyToUpdate].push(response?.data?.value ?? fieldValues.value);
              });
            }
            store.commit('ADD_TOAST_SUCCESS', `Uw ${metadata.value.modelDisplayName} is succesvol toegevoegd.`, { root: true });
            goToGrid();
          }
        })
        .catch((err) => store.commit('ADD_TOAST_ERROR', err, { root: true }))
        .finally(() => store.commit('dynamicform/SET_LOADING_STATUS', { loadingStatus: false, identifier: componentName.value }));
    };

    function goToGrid() {
      if (lastPage.value) {
        router.push(lastPage.value);
      } else if (customGridRoute.value) {
        router.push({
          name: customGridRoute.value
        });
      } else {
        router.push({
          name: 'dynamicgridview',
          params: {
            name: componentName.value
          }
        });
      }
    }

    return {
      metadata,
      fieldValues,
      loadingStatus,
      validationErrors,
      componentName,
      onSubmit,
      goToGrid
    };
  }
};
</script>