<template>
  <CForm onsubmit="return false" id="form">
    <CRow>
      <CCol>
        <CRow class="mb-3" v-for="property in stretchedProperties?.filter((x) => !getDisplayType(x).includes('file'))" :key="property.displayName">
          <CCol xs="3">
            <label class="col-form-label" for="property.displayName">{{ property.displayName }}</label>
          </CCol>
          <CCol xs="9">
            <FilePicker
              v-if="getDisplayType(property) == 'foldertree'"
              v-model="fieldValues[property.variableName]"
              :extensionsConfig="property.extensionsConfig"
              :requiredVariable="fieldValues[property.requiredVariableName]"
            />
            <input
              v-if="
                !getDisplayType(property).includes('file') && getDisplayType(property) !== 'dropdown' && getDisplayType(property) !== 'foldertree'
              "
              :class="getDisplayType(property) === 'checkbox' ? 'form-check-input' : 'form-control'"
              :type="getDisplayType(property)"
              :id="property.displayName"
              v-model="fieldValues[property.variableName]"
              @input="onFieldValueChanged(property, $event)"
              :step="property.allowFractions ? 'any' : undefined"
              :readonly="shouldBeDisabled(property) ? 'readonly' : undefined"
            />
            <TmSelect
              :property="property"
              :asyncOptions="asyncOptions"
              v-model="fieldValues[property.variableName]"
              @update:model-value="() => onFieldValueChanged(property, { target: { value: fieldValues[property.variableName] } })"
              :getDisplayType="getDisplayType"
              :shouldBeDisabled="shouldBeDisabled"
            />

            <span v-if="getValidationErrorOrSuggestion(property, validationErrors).validationError" style="display: block" class="text-danger">{{
              getValidationErrorOrSuggestion(property, validationErrors).validationError
            }}</span>
            <div v-if="property.showSuggestions && getValidationErrorOrSuggestion(property, validationErrors).suggestions">
              <span class="text-info">Bedoelt u </span>
              <span
                @click="changePropertyValue(property, suggestion)"
                class="text-info"
                v-for="(suggestion, index) in getValidationErrorOrSuggestion(property, validationErrors).suggestions"
                :key="suggestion"
              >
                <!--If more than two options have to be supported, you can change this v-if to index > 0 && index != getValidationErrorOrSuggestion(property, validationErrors).length-1 
          The reason I omitted that is that for now only two are supported anyway, and that prevents this method from being called every input change twice. -->
                <span v-if="index > 0">
                  {{ ' of ' }}
                </span>
                <span style="cursor: pointer; text-decoration: underline">
                  {{ suggestion }}
                </span>
              </span>
              <span class="text-info">?</span>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- Above is stretched, below is not stretched (duplicate code, could be improved but is difficult) -->
    <CRow>
      <CCol v-for="(column, index) in columns" :key="index">
        <CRow class="mb-3" v-for="property in column.properties?.filter((x) => !getDisplayType(x).includes('file'))" :key="property.displayName">
          <CCol>
            <label class="col-form-label" for="property.displayName">{{ property.displayName }}</label>
          </CCol>
          <CCol :xs="stretchInputFields ? 9 : undefined">
            <div>
              <FilePicker
                v-if="getDisplayType(property) == 'foldertree'"
                v-model="fieldValues[property.variableName]"
                :extensionsConfig="property.extensionsConfig"
                :requiredVariable="fieldValues[property.requiredVariableName]"
              />
            </div>
            <input
              v-if="
                !getDisplayType(property).includes('file') && getDisplayType(property) !== 'dropdown' && getDisplayType(property) !== 'foldertree'
              "
              :class="getDisplayType(property) === 'checkbox' ? 'form-check-input' : 'form-control'"
              :type="getDisplayType(property)"
              :id="property.displayName"
              v-model="fieldValues[property.variableName]"
              @input="onFieldValueChanged(property, $event)"
              @change="onFieldValueChanged(property, $event)"
              :step="property.allowFractions ? 'any' : undefined"
              :readonly="shouldBeDisabled(property) ? 'readonly' : undefined"
            />
            <TmSelect
              :property="property"
              :asyncOptions="asyncOptions"
              v-model="fieldValues[property.variableName]"
              @update:model-value="() => onFieldValueChanged(property, { target: { value: fieldValues[property.variableName] } })"
              :getDisplayType="getDisplayType"
              :shouldBeDisabled="shouldBeDisabled"
            />

            <span v-if="getValidationErrorOrSuggestion(property, validationErrors).validationError" style="display: block" class="text-danger">{{
              getValidationErrorOrSuggestion(property, validationErrors).validationError
            }}</span>
            <div v-if="property.showSuggestions && getValidationErrorOrSuggestion(property, validationErrors).suggestions">
              <span class="text-info">Bedoelt u </span>
              <span
                @click="changePropertyValue(property, suggestion)"
                class="text-info"
                v-for="(suggestion, sugIndex) in getValidationErrorOrSuggestion(property, validationErrors).suggestions"
                :key="suggestion"
              >
                <span v-if="sugIndex > 0">
                  {{ ' of ' }}
                </span>
                <span style="cursor: pointer; text-decoration: underline">
                  {{ suggestion }}
                </span>
              </span>
              <span class="text-info">?</span>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow v-for="property in editorProperties" :key="property.displayName">
      <CCol xs="3">
        <label class="col-form-label" for="property.displayName">{{ property.displayName }}</label>
      </CCol>
      <CCol xs="9">
        <advanced-editor v-model="fieldValues[property.variableName]" :metadata="property.variables" />
      </CCol>
    </CRow>
    <CRow v-for="property in fileProperties" :key="property.displayName">
      <FormImageUpload
        :baseUrl="url"
        v-model="fieldValues[property.variableName]"
        :uploadConfig="property.uploadConfiguration"
        :downloadConfig="property.downloadConfiguration"
        :deleteConfig="property.deleteConfiguration"
        :requiredVariable="fieldValues[property.requiredVariableName]"
      />
    </CRow>
    <CRow v-for="property in multiFileProperties" :key="property.displayName">
      <MultiFileViewer
        :baseUrl="url"
        v-model="fieldValues[property.variableName]"
        :uploadConfig="property.uploadConfiguration"
        :downloadConfig="property.downloadConfiguration"
        :deleteConfig="property.deleteConfiguration"
        :mainImageConfig="property.mainImageConfiguration"
        :requiredVariable="fieldValues[property.requiredVariableName]"
      />
    </CRow>
  </CForm>
</template>

<script>
import { useStore } from 'vuex';
import { computed, watch, toRefs, ref } from 'vue';
import { getValidationError, getErrorMessage, getSuggestions } from '@/helpers/formHelper.js';
import { getDisplayType } from '@/helpers/gridHelper.js';
import FormImageUpload from '@/components/FormImageUpload.vue';
import MultiFileViewer from '@/components/MultiFileViewer.vue';
import FilePicker from '@/components/FilePicker.vue';
import TmSelect from '@/components/TmSelect.vue';

import AdvancedEditor from '@/components/AdvancedEditor.vue';
import { fileEndpoint } from '@/services/constants.js';
import moment from 'moment';
import CrudService from '../../services/CrudService';
import { createUrl } from '@/helpers/apiHelper.js';

export default {
  name: 'DynamicForm',
  components: { FormImageUpload, MultiFileViewer, AdvancedEditor, FilePicker, TmSelect },
  props: {
    metadata: {
      required: true
    },
    data: {
      required: false
    },
    name: {
      required: true
    },
    isEdit: {
      required: true
    }
  },
  setup(props) {
    const { metadata: metadata, data: data, name: componentName, isEdit: isEdit } = toRefs(props);

    const store = useStore();

    const url = store.state.baseURL + fileEndpoint;

    const meta = computed(() => store.state.dynamicform[componentName.value].metadata);
    const fieldValues = computed(() => store.state.dynamicform[componentName.value].fieldValues);
    const validationErrors = computed(() => store.state.dynamicform[componentName.value].validationErrors);

    const stretchInputFields = computed(() => {
      return meta.value?.stretchInputFields ?? false;
    });

    const stretchedProperties = computed(() => {
      return meta.value?.properties?.filter((p) => p.isStretched);
    });

    const columns = computed(() => {
      if (meta.value?.properties?.length === undefined) {
        return [];
      }
      let properties = meta.value.properties.filter(
        (p) => !p.isStretched && !getDisplayType(p).includes('file') && !getDisplayType(p).includes('editor')
      );
      let columns = [];
      let numberOfColumns = properties.length > 9 ? 2 : 1;
      let mid = Math.ceil(properties.length / numberOfColumns);
      for (let col = 0; col < numberOfColumns; col++) {
        columns.push({ properties: properties.slice(col * mid, col * mid + mid) });
      }

      return columns;
    });

    const editorProperties = computed(() => meta.value?.properties?.filter((p) => getDisplayType(p) === 'editor'));
    const fileProperties = computed(() =>
      meta.value?.properties?.filter((p) => getDisplayType(p) === 'file' && (!p.requiredVariableName || fieldValues.value[p.requiredVariableName]))
    );
    const multiFileProperties = computed(() =>
      meta.value?.properties?.filter(
        (p) => getDisplayType(p) === 'multifile' && (!p.requiredVariableName || fieldValues.value[p.requiredVariableName])
      )
    );

    function onFieldValueChanged(property, e) {
      store.dispatch('dynamicform/getError', {
        property,
        value: e.target.value,
        id: data.value?.id,
        identifier: componentName.value,
        fieldValues: fieldValues.value
      });

      const linkedProperties = metadata.value.properties.filter(
        (p) => p.uniqueCombinations?.length && p.uniqueCombinations.includes(property.variableName)
      );
      console.log({
        properties: linkedProperties,
        fieldValues: fieldValues.value,
        id: data.value?.id,
        identifier: componentName.value
      });
      linkedProperties.forEach((linkedProperty) => {
        store.dispatch('dynamicform/getError', {
          property: linkedProperty,
          value: fieldValues.value[linkedProperty.variableName],
          id: data.value?.id,
          identifier: componentName.value,
          fieldValues: fieldValues.value
        });
      });

      const dynamicOptions = metadata.value.properties.filter(
        (p) =>
          p.dynamicOptions &&
          (p.dynamicOptions.pathParameter == property.variableName ||
            (p.dynamicOptions.queryParameters?.length &&
              p.dynamicOptions.queryParameters.filter((q) => q.variableName == property.variableName)?.length))
      );
      dynamicOptions.forEach((x) => {
        getOptions(x);
      });
    }

    function reformatInputDate() {
      var dateProperties = metadata.value?.properties.filter((x) => getDisplayType(x).includes('date'));
      dateProperties.forEach((p) => {
        let formatterDate = data.value[p.variableName];
        // Take out ms if there is
        if (formatterDate) {
          formatterDate = formatterDate.split('.')[0];
          if (moment(formatterDate, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
            formatterDate = moment(formatterDate, 'YYYY-MM-DDTHH:mm:ss', true).format(
              'yyyy-MM-DD' + (getDisplayType(p) == 'datetime-local' ? ' HH:mm' : '')
            );
            data.value[p.variableName] = formatterDate;
          }
        }
      });
    }

    function updateOptions() {
      if (!isEdit.value) return;
      const options = metadata.value?.properties.filter((x) => x.options?.length !== undefined);
      for (let option of options) {
        if (option.displayPropertiesPath) {
          const x = option.displayPropertiesPath[0];
          let path = fieldValues.value[x];

          for (let i = 1; i < option.displayPropertiesPath.length; i++) {
            path = path[options.displayPropertiesPath[i]];
          }

          // If the options do not contain the current value
          if (path && !option.options.filter((x) => x.key == fieldValues.value[option.keyProperty])?.length) {
            let value = path[option.displayProperties[0]];
            for (let j = 1; j < option.displayProperties.length; j++) {
              value = value[option.displayProperties[j]];
            }

            option.options.push({
              key: path[option.keyProperty],
              displayText: value
            });

            fieldValues.value[option.variableName] = value;
          }
        }
      }
    }

    const asyncOptions = ref({});
    const getOptions = (property) => {
      asyncOptions.value[property.variableName] = [];

      const url = createUrl(property.dynamicOptions, fieldValues.value);
      if (!url) return;

      CrudService.getGridData(url).then((response) => {
        if (response.data.success) {
          const options = response.data.value.map((x) => {
            let display = undefined;
            property.displayProperties.forEach((d) => {
              const split = d.split('.');
              let lastThing = x;
              split.forEach((s) => {
                lastThing = lastThing[s];
              });

              if (display === undefined) display = lastThing;
              else display += '-' + lastThing;
            });

            return {
              key: x[property.keyProperty],
              displayText: display
            };
          });

          if (!options.filter((o) => o.key == fieldValues.value[property.variableName]).length) {
            fieldValues.value[property.variableName] = undefined;
          }

          asyncOptions.value[property.variableName] = options;
        }
      });
    };

    function shouldBeDisabled(property) {
      if (!property.editable) return true;
      if (!property.disabledConditions?.length) return false;

      for (const condition of property.disabledConditions) {
        const value = fieldValues.value[condition.propertyName];
        if ((condition.equals && value == condition.equals) || (condition.notEmpty && !value) || (condition.notEmpty === false && value)) {
          if (condition.clearWhenDisabled) {
            fieldValues.value[property.variableName] = undefined;
          }
          return true;
        }
      }
      return false;
    }

    watch(
      metadata,
      () => {
        if (metadata.value?.properties) {
          metadata.value.properties.forEach((p) => {
            if (p.options) {
              p.options = p.options.sort((a, b) => a.displayText.localeCompare(b.displayText));
            }
            if (p.dynamicOptions) {
              getOptions(p, true);
            }
          });
          store.commit('dynamicform/SET_METADATA', { payload: metadata.value, identifier: componentName.value });

          if (data.value) {
            reformatInputDate();
            updateOptions();
          }

          if (isEdit.value == false) {
            initializeDefaultValues();
          }
          initializeValidationErrors();
        }
      },
      { immediate: true }
    );

    watch(
      data,
      () => {
        if (data.value) {
          console.log(data.value);
          store.commit('dynamicform/SET_FIELD_VALUES', { payload: data.value, identifier: componentName.value });
          if (metadata.value?.properties) {
            reformatInputDate();
            initializeValidationErrors();
            updateOptions();
          }
        }
      },
      { immediate: true }
    );

    function initializeDefaultValues() {
      let initialData = {};
      metadata.value.properties.forEach((p) => {
        if (p.defaultValue?.value) {
          initialData[p.variableName] = p.defaultValue.value;
        } else if (p.defaultValue?.where && p.usedValues) {
          const desiredValue = p.defaultValue.where.equals;
          const comparisonProperty = p.defaultValue.where.propertyName;
          const value = p.usedValues.filter((opt) => opt[comparisonProperty] == desiredValue)[0];

          if (value) {
            initialData[p.variableName] = p.keyProperty ? value[p.keyProperty] : value[p.variableName];
          }
        }
      });

      store.state.dynamicform[componentName.value].fieldValues = initialData;
      initializeValidationErrors();
    }

    function initializeValidationErrors() {
      metadata.value.properties.forEach((property) => {
        const value = data.value[property.variableName];
        const error = getErrorMessage(property, value, data.value?.id, fieldValues.value);
        store.commit('dynamicform/CLEAR_VALIDATION_ERROR', { propertyName: property.variableName, identifier: componentName.value });
        if (error) store.commit('dynamicform/SET_VALIDATION_ERROR', { error, propertyName: property.variableName, identifier: componentName.value });
      });
    }

    function getValidationErrorOrSuggestion(property, validationErrors) {
      var validationError = getValidationError(property, validationErrors);
      if (validationError) return { validationError };

      if (property.showSuggestions) {
        var suggestions = getSuggestions(property, fieldValues.value[property.variableName]);
        return suggestions?.length ? { suggestions } : {};
      }
      return {};
    }

    function changePropertyValue(property, suggestion) {
      fieldValues.value[property.variableName] = suggestion;
      onFieldValueChanged(property, { target: { value: suggestion } });
    }

    return {
      meta,
      fieldValues,
      validationErrors,
      getValidationErrorOrSuggestion,
      onFieldValueChanged,
      getDisplayType,
      columns,
      url,
      stretchedProperties,
      fileProperties,
      changePropertyValue,
      multiFileProperties,
      editorProperties,
      shouldBeDisabled,
      getOptions,
      asyncOptions,
      stretchInputFields
    };
  }
};
</script>