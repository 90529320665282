<template>
  <CRow>
    <CCol :xs="12" :lg="9">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Componenten</strong>
        </CCardHeader>
        <CCardBody>
          <CButton @click="onAdd" color="primary" class="btn-sm mb-2"> Toevoegen </CButton>
          &nbsp;
          <CButton @click="onEdit" :disabled="componentsSelectedRow?.id === undefined" color="light" class="btn-sm mb-2"> Wijzigen </CButton>
          &nbsp;
          <label for="import" class="btn btn-light btn-sm mb-2">Importeer</label>
          <input
            id="import"
            style="display: none"
            @change="onEventFilePicked"
            type="file"
            ref="importFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          &nbsp;
          <CButton @click="downloadTemplate" color="light" class="btn-sm mb-2"> Voorbeeld </CButton>
          &nbsp;
          <CButton @click="addToKardex" :disabled="!gridRows?.filter((x) => x.kardex)?.length" color="light" class="btn-sm mb-2">
            Naar Kardex
          </CButton>
          &nbsp;
          <CButton
            @click="
              () => {
                deleteModalActive = true;
              }
            "
            :disabled="componentsSelectedRow?.id === undefined"
            color="danger"
            class="btn-sm mb-2"
            style="float: right"
          >
            Verwijderen
          </CButton>
          <dynamic-grid @double-clicked="onEdit" name="CComponents" :metadata="componentsMetadata" :data="componentsData" :autoPagination="true" />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol :xs="12" :lg="3">
      <CCard class="mb-3">
        <CCardHeader>
          <strong>Afbeelding</strong>
        </CCardHeader>
        <div
          @click="
            () => {
              imageModalActive = !!componentsSelectedRow?.file;
            }
          "
        >
          <CCardBody style="height: 330px">
            <CImage class="mb-2" style="max-height: 100%" v-if="componentsSelectedRow?.file" rounded thumbnail :src="lastImage" />
          </CCardBody>
        </div>
        <CCardFooter class="text-muted">{{
          componentsSelectedRow?.file
            ? componentsSelectedRow?.file.originalName.substring(0, 20) ?? 'Geen bestandsnaam beschikbaar'
            : 'Geen afbeelding beschikbaar'
        }}</CCardFooter>
      </CCard>
    </CCol>
  </CRow>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <strong>Tools</strong>
        </CCardHeader>
        <CCardBody>
          <CButton @click="onAddTool" color="primary" class="btn-sm mb-2"> Toevoegen </CButton>
          &nbsp;
          <CButton @click="onEditTool" :disabled="toolsSelectedRow?.id === undefined" color="light" class="btn-sm mb-2"> Wijzigen </CButton>
          <dynamic-grid @double-clicked="onToolsDoubleClick" name="CTools" :metadata="toolsMetadata" :data="toolsData" :autoPagination="true" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div :style="imageModalActive ? undefined : 'display:none'">
    <CModal
      size="md"
      :visible="imageModalActive"
      @close="
        () => {
          imageModalActive = false;
        }
      "
    >
      <CModalHeader>
        <CModalTitle>{{
          componentsSelectedRow?.file ? componentsSelectedRow?.file.originalName ?? 'Geen bestandsnaam beschikbaar' : 'Geen afbeelding beschikbaar'
        }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CImage v-if="componentsSelectedRow?.file" rounded thumbnail :src="lastImage" />
      </CModalBody>
    </CModal>
  </div>
  <confirmation-modal
    :style="deleteModalActive ? undefined : 'display:none'"
    :modalActive="deleteModalActive"
    modalBody="Weet u zeker dat u de geselecteerde component wilt verwijderen?"
    @on-continue="onDelete"
    @on-cancel="() => (deleteModalActive = false)"
  />
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { computed, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import DownloadClient from '@/services/DownloadClient';

export default {
  name: 'ComponentsView',
  components: { DynamicGrid, ConfirmationModal },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    store.commit('componentsview/CLEAR_HISTORY');

    const lastImage = computed(() => store.state.componentsview.lastImage);
    const imageModalActive = ref(false);
    const deleteModalActive = ref(false);
    const importFile = ref(null);

    const componentsMetadata = computed(() => store.state.componentsview.componentsMetadata);
    const componentsData = computed(() => store.state.componentsview.componentsData);

    const toolsData = computed(() => store.state.componentsview.toolsData);
    const toolsMetadata = computed(() => store.state.componentsview.toolsMetadata);

    if (!store.state.dynamicgrid['CComponents']) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'CComponents');
      store.dispatch('componentsview/getComponentsMetadata');
    } else {
      store.state.dynamicgrid['CComponents'].selectedRows = [];
    }

    store.commit('dynamicgrid/SET_NEW_GRID', 'CTools');
    store.dispatch('componentsview/getComponentsData');
    store.dispatch('componentsview/getToolsMetadata');

    store.commit('SET_LAST_PAGE', { name: 'components' }, { root: true });

    const gridRows = computed(() => store.state.dynamicgrid['CComponents'].rows);

    const componentsSelectedRow = computed(() => {
      if (store.state.dynamicgrid['CComponents'].selectedRows.length > 0) {
        return store.state.dynamicgrid['CComponents'].selectedRows[0];
      }
      return undefined;
    });

    const toolsSelectedRow = computed(() => {
      if (store.state.dynamicgrid['CTools'].selectedRows.length > 0) {
        return store.state.dynamicgrid['CTools'].selectedRows[0];
      }
      return undefined;
    });

    const onEventFilePicked = () => {
      let file = importFile.value.files[importFile.value.files.length - 1];
      if (!file.name) return;
      store.dispatch('componentsview/importeerComponentFromExcel', file);
      importFile.value.value = '';
    };

    watch(componentsSelectedRow, () => {
      if (componentsSelectedRow.value) {
        store.dispatch('componentsview/getToolsData', componentsSelectedRow.value.id);
      } else {
        store.commit('componentsview/SET_TOOLS_DATA', []);
      }

      if (componentsSelectedRow.value?.file) {
        store.dispatch('componentsview/downloadImage', componentsSelectedRow.value.id);
      }
      store.commit('dynamicgrid/SET_SELECTED_ROWS', { payload: [], identifier: 'CTools' });
    });

    function onAdd() {
      router.push({
        name: 'dynamicformview',
        params: {
          name: 'components',
          callBackPage: 'components'
        }
      });
    }

    function onEdit() {
      router.push({
        name: 'componentscrud',
        params: {
          id: componentsSelectedRow.value.id
        }
      });
    }

    function onAddTool() {
      router.push({
        name: 'toolscrud'
      });
    }

    function onEditTool() {
      router.push({
        name: 'toolscrud',
        params: {
          id: toolsSelectedRow.value.id
        }
      });
    }

    function onToolsDoubleClick() {
      router.push({
        name: 'toolscrud',
        params: {
          id: toolsSelectedRow.value.id
        }
      });
    }

    function onDelete() {
      store.dispatch('componentsview/deleteComponent', componentsSelectedRow.value.id);
      store.state.dynamicgrid['CComponents'].selectedRows = [];
      deleteModalActive.value = false;
    }

    function downloadTemplate() {
      DownloadClient()
        .get(`files/export/components`, { responseType: 'arraybuffer' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          );
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Componenten_Voorbeeld`);
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit('ADD_TOAST_SUCCESS', 'Het voorbeeld is geëxporteerd.', { root: true });
        })
        .catch(() => store.commit('ADD_TOAST_ERROR', 'Het voorbeeld kon niet worden geëxporteerd.', { root: true }));
    }

    function addToKardex() {
      store.dispatch(
        'componentsview/addToKardex',
        gridRows.value.filter((x) => x.kardex)
      );
    }

    return {
      componentsMetadata,
      componentsData,
      toolsData,
      toolsMetadata,
      onAdd,
      onEdit,
      componentsSelectedRow,
      toolsSelectedRow,
      onDelete,
      deleteModalActive,
      imageModalActive,
      lastImage,
      onEventFilePicked,
      importFile,
      onToolsDoubleClick,
      onAddTool,
      onEditTool,
      downloadTemplate,
      gridRows,
      addToKardex
    };
  }
};
</script>