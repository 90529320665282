<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <strong style="font-size: 20px">Machines</strong>
        </CCardHeader>
        <CCardBody>
          <CButton
            @click="goToWissellijst"
            :disabled="!machinesSelectedRow?.wissellijst?.id || toolmagazijnCheckIsExpired"
            color="primary"
            class="btn-md mb-2"
          >
            Bekijk wissellijst
          </CButton>
          &nbsp;
          <CButton @click="goToToollijsten" :disabled="!machinesSelectedRow?.machineToollijsten?.length" color="light" class="btn-md mb-2">
            Bekijk toollijsten
          </CButton>
          &nbsp;
          <CButton @click="goToMagazijnen" :disabled="!machinesSelectedRow?.machineMagazijnen?.length" color="light" class="btn-md mb-2">
            Wijzig magazijnen
          </CButton>
          &nbsp;
          <CButton
            @click="goToWissellijstenHistory"
            :disabled="!machinesSelectedRow?.wissellijsten?.filter((w) => w.status == 2)?.length"
            color="light"
            class="btn-md mb-2"
          >
            Wissellijsten historie
          </CButton>
          &nbsp;
          <CButton @click="goToVersletenHistory" :disabled="!machinesSelectedRow?.vervangMomenten?.length" color="light" class="btn-md mb-2">
            Vervangingen historie
          </CButton>
          &nbsp;
          <CButton
            @click="goToToolmagazijnInlezen"
            :disabled="machinesSelectedRow?.toolmagazijnImportProcedure == null"
            color="light"
            class="btn-md mb-2"
          >
            Toolmagazijn inlezen
          </CButton>
          <dynamic-grid
            name="MMachines"
            :metadata="machinesMetadata"
            :data="machinesData"
            :tabletView="true"
            :autoPagination="true"
            :rowClassRules="rowClassRules"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'TabletMachinesView',
  components: { DynamicGrid },
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    if (!store.state.dynamicgrid['MMachines']) {
      store.commit('dynamicgrid/SET_NEW_GRID', 'MMachines');
      store.dispatch('tabletmachinesview/getMachinesMetadata');
    }

    store.dispatch('tabletmachinesview/getMachinesData');

    const rowClassRules = {
      'red-row': function (params) {
        return params.data.status === '0';
      },
      'orange-row': function (params) {
        return isToolmagazijnCheckExpired(params.data);
      },
      'green-row': function (params) {
        return params.data.status === '1';
      }
    };

    const toolmagazijnCheckIsExpired = computed(() => {
      const machine = machinesSelectedRow.value;

      return isToolmagazijnCheckExpired(machine);
    });

    const machinesSelectedRow = computed(() => {
      if (store.state.dynamicgrid['MMachines'].selectedRows.length > 0) {
        return store.state.dynamicgrid['MMachines'].selectedRows[0];
      }
      return undefined;
    });

    watch(machinesSelectedRow, () => {
      if (machinesSelectedRow.value) {
        store.commit('tabletmachinesview/SET_SELECTED_WISSELLIJST_ID', machinesSelectedRow.value.wissellijst?.id);
        store.commit('tabletmachinesview/SET_SELECTED_TOOLLIJSTEN', machinesSelectedRow.value.machineToollijsten);
      }
    });

    const machinesMetadata = computed(() => store.state.tabletmachinesview.machinesMetadata);
    const machinesData = computed(() => store.state.tabletmachinesview.machinesData);

    function isToolmagazijnCheckExpired(machine) {
      if (machine && machine.toolmagazijnImportProcedure) {
        if (!machine.leesdatum) {
          return true;
        }

        const date = new Date(machine.leesdatum);
        const now = new Date();
        now.setHours(now.getHours() - 4);

        if (date < now) {
          return true;
        }
      }

      return false;
    }

    function goToWissellijst() {
      if (machinesSelectedRow.value.wissellijst.id) {
        router.push({
          name: 'tabletmachinewissellijst',
          params: {
            wissellijstId: machinesSelectedRow.value.wissellijst.id
          }
        });
      }
    }

    function goToToolmagazijnInlezen() {
      if (machinesSelectedRow.value.toolmagazijnImportProcedure) {
        if (
          machinesSelectedRow.value.toolmagazijnImportProcedure.leesRegister &&
          !machinesSelectedRow.value.toolmagazijnImportProcedure.isRegisterFileSameAsMagazijnImport
        ) {
          router.push({
            name: 'tabletmachinetoolregisterimport',
            params: {
              machineId: machinesSelectedRow.value.id
            }
          });
        } else {
          router.push({
            name: 'tabletmachinetoolmagazijnimport',
            params: {
              machineId: machinesSelectedRow.value.id
            }
          });
        }
      }
    }

    function goToToollijsten() {
      if (machinesSelectedRow.value.machineToollijsten.length) {
        router.push({
          name: 'tabletmachinetoollijsten',
          params: {
            machineId: machinesSelectedRow.value.id
          }
        });
      }
    }

    function goToMagazijnen() {
      if (machinesSelectedRow.value.machineMagazijnen.length) {
        router.push({
          name: 'tabletmachinemagazijnen',
          params: {
            machineId: machinesSelectedRow.value.id
          }
        });
      }
    }

    function goToWissellijstenHistory() {
      router.push({
        name: 'tabletmachinewissellijstenhistory',
        params: {
          machineId: machinesSelectedRow.value.id
        }
      });
    }

    function goToVersletenHistory() {
      router.push({
        name: 'tabletversletenhistory',
        params: {
          machineId: machinesSelectedRow.value.id
        }
      });
    }

    return {
      machinesMetadata,
      machinesData,
      machinesSelectedRow,
      toolmagazijnCheckIsExpired,
      goToWissellijst,
      goToToollijsten,
      goToMagazijnen,
      goToWissellijstenHistory,
      goToToolmagazijnInlezen,
      rowClassRules,
      goToVersletenHistory
    };
  }
};
</script>
