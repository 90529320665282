<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToVersletenHistory">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px">Vervangingen - {{ machineNaam }}</strong>
        </CCardHeader>
        <CCardBody>
          <CButton @click="printLabels" :disabled="!vervangingen?.filter((x) => x.printLabel)?.length" color="primary" class="btn-md mb-2">
            Print Labels
          </CButton>
          &nbsp;
          <dynamic-grid
            name="MVervangingenHistory"
            :metadata="vervangingenHistoryMetadata"
            :data="vervangingenHistoryData"
            :tabletView="true"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from 'vuex';
import DynamicGrid from '@/views/dynamicgrid/DynamicGrid.vue';
import { computed, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CIcon } from '@coreui/icons-vue';
import { cilArrowLeft } from '@coreui/icons';

export default {
  name: 'TabletVervangingenHistory',
  components: { DynamicGrid, CIcon },
  props: {
    machineId: {
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    store.commit('dynamicgrid/SET_NEW_GRID', 'MVervangingenHistory');

    store.dispatch('tabletvervangingenhistory/getVervangingenHistoryMetadata');

    const machineNaam = computed(() => store.state.tabletvervangingenhistory.machineNaam);

    watch(
      machineId,
      () => {
        if (machineId.value) {
          store.dispatch('tabletvervangingenhistory/getMachine', machineId.value);
        }
      },
      { immediate: true }
    );

    const vervangingenHistoryMetadata = computed(() => store.state.tabletvervangingenhistory.vervangingenHistoryMetadata);
    const vervangingenHistoryData = computed(() => store.state.tabletvervangingenhistory.vervangingenHistoryData);
    const vervangMomentenIds = computed(() => store.state.tabletvervangingenhistory.vervangMomentenIds);
    const vervangingen = computed(() => store.state.dynamicgrid['MVervangingenHistory'].rows);

    const vervangingenHistorySelectedRow = computed(() => {
      if (store.state.dynamicgrid['MVervangingenHistory'].selectedRows.length > 0) {
        return store.state.dynamicgrid['MVervangingenHistory'].selectedRows[0];
      }
      return undefined;
    });

    watch(
      vervangMomentenIds,
      () => {
        if (vervangMomentenIds.value) {
          store.dispatch('tabletvervangingenhistory/getVervangingenHistoryData', vervangMomentenIds.value);
        }
      },
      { immediate: true }
    );

    function printLabels() {
      store.dispatch(
        'tabletvervangingenhistory/printLabels',
        vervangingen.value.filter((x) => x.printLabel)
      );
    }

    function goToVersletenHistory() {
      router.push({
        name: 'tabletversletenhistory',
        params: {
          machineId: machineId.value
        }
      });
    }

    return {
      vervangingenHistoryMetadata,
      vervangingenHistoryData,
      vervangingenHistorySelectedRow,
      cilArrowLeft,
      goToVersletenHistory,
      machineNaam,
      printLabels,
      vervangingen,
      vervangMomentenIds
    };
  }
};
</script>