import { getFilterType, getDisplayType } from '@/helpers/gridHelper.js';
import moment from 'moment';
import { dateComparator } from '@/helpers/gridHelper.js';
import { AG_GRID_LOCALE_NL } from '@/localisation/localenl.js';

const namespaced = true;

const state = {};

const mutations = {
  SET_COLUMNS(state, { payload, identifier }) {
    state[identifier].columns = payload;
  },

  SET_COLUMN(state, { payload, rowId, colId, rowIdName, identifier }) {
    rowIdName ??= 'id';

    const row = state[identifier].rows.filter((r) => r[rowIdName] == rowId)[0];
    row[colId] = payload;
  },

  SET_ROWS(state, { payload, identifier }) {
    state[identifier].rows = payload;
  },

  REMOVE_ROW(state, { id, identifier }) {
    const index = state[identifier].rows.findIndex((row) => row.id === id);
    state[identifier].rows.splice(index, 1);
  },

  SET_SELECTED_ROWS(state, { payload, identifier }) {
    console.log('HELLO');
    state[identifier].selectedRows = payload;
  },

  SET_METADATA(state, { payload, identifier }) {
    state[identifier].metadata = payload;
  },

  SET_FILTER_MODEL(state, { payload, identifier }) {
    state[identifier].filterModel = payload;
  },

  SET_SORT_MODEL(state, { payload, identifier }) {
    state[identifier].sortModel = payload;
  },

  SET_PAGE_NUMBER(state, { payload, identifier }) {
    state[identifier].pageNumber = payload;
  },

  SET_PAGE_SIZE(state, { payload, identifier }) {
    state[identifier].pageSize = payload;
  },

  SET_NEW_GRID(state, identifier) {
    state[identifier] = {
      columns: [],
      rows: [],
      selectedRows: [],
      metadata: {},
      filterModel: undefined,
      sortModel: undefined,
      pageNumber: 0,
      pageSize: 10
    };
  }
};

const actions = {
  getColumns({ commit }, { component, identifier, lockColumns }) {
    let columns = [];
    component.properties.forEach((property) => {
      function isDisabled(conditions, row) {
        for (let condition of conditions) {
          if (
            row[condition.propertyName] == condition.equals ||
            (condition.notEmpty && !row[condition.propertyName]) ||
            (condition.notEmpty === false && !!row[condition.propertyName])
          ) {
            if (!condition.and || (condition.and && row[condition.and.propertyName] != condition.and.notEquals)) {
              return true;
            }
          }
        }
        return false;
      }
      let headerName = property.displayName;
      let field = property.variableName[0].toLowerCase() + property.variableName.slice(1);
      let filter = getFilterType(property.displayType);
      let sortable = true;
      let pinned = property.pinned ? 'right' : undefined;
      let lockPinned = property.pinned ? true : undefined;
      let cellClass = property.pinned ? 'lock-pinned' : '';
      let editable = property.editable;
      let valueGetter = undefined;
      let cellRenderer = undefined;
      let resizable = true;
      let width = property.columnWidth ?? 150;
      let enums = property.enumValues;
      let comparator = undefined;
      let disabledCondition = undefined;
      let cellEditorPopup = undefined;
      let lockPosition = lockColumns;
      let valueFormatter = undefined;
      let headerCheckboxSelection = undefined;
      let checkboxSelection = undefined;
      let showDisabledCheckboxes = undefined;
      let isRowSelectable = undefined;

      if (property.valueFormatter) {
        valueFormatter = (params) => {
          let v = params.value;
          if (!v) return v;

          for (let formatter of property.valueFormatter.appendFormatters) {
            if (formatter.value) {
              v += formatter.value;
            } else if (formatter.property) {
              let obj = params.data;

              if (formatter.TakeFirstAndThenProperty) {
                obj = obj[formatter.TakeFirstAndThenProperty][0];
              }

              let ps = formatter.property.split('.');
              ps.forEach((pp) => {
                if (obj) {
                  obj = obj[pp];
                }
              });

              if (!obj) return;
              v += obj;
            }
          }

          if (v != '') {
            return v;
          }
        };
      }

      const displayType = getDisplayType(property);
      if (displayType && displayType.includes('date')) {
        valueGetter = (params) => {
          const isValidDateTime = moment(params.data[property.variableName]).isValid();
          if (!isValidDateTime) return '';

          return displayType == 'datetime-local'
            ? moment(params.data[property.variableName]).format('DD-MM-YYYY HH:mm')
            : moment(params.data[property.variableName]).format('DD-MM-YYYY');
        };
        comparator = dateComparator;
      } else if (displayType === 'checkbox') {
        if (property.selectAll) {
          headerCheckboxSelection = true;
          checkboxSelection = true;
          showDisabledCheckboxes = true;
          if (property.disabledConditions) {
            isRowSelectable = function (params) {
              return !isDisabled(property.disabledConditions, params.data);
            };
          }
        } else {
          valueGetter = (params) => {
            return params.data[property.variableName] == true ? AG_GRID_LOCALE_NL.yes : AG_GRID_LOCALE_NL.no;
          };
        }
      } else if (enums?.length) {
        valueGetter = (params) => {
          return enums[params.data[property.variableName]];
        };
      } else if (property.displayType == 10) {
        cellRenderer = 'checkboxRenderer';
        if (property.disabledConditions) {
          disabledCondition = (row) => isDisabled(property.disabledConditions, row);
        }
      } else if (property.displayType == 11) {
        cellRenderer = 'dropdownCellRenderer';
        cellEditorPopup = true;
        cellClass += ' ag-grid-dropdown-cell';
      }

      columns.push({
        headerCheckboxSelection,
        checkboxSelection,
        showDisabledCheckboxes,
        isRowSelectable,
        headerName,
        field,
        cellEditorPopup,
        filter,
        sortable,
        width,
        valueGetter,
        lockPosition,
        valueFormatter,
        cellRenderer,
        comparator,
        disabledCondition,
        resizable,
        filterParams: { newRowsAction: 'keep', comparator },
        pinned,
        lockPinned,
        cellClass,
        editable,
        suppressCellFlash: true
      });
    });

    commit('SET_COLUMNS', { payload: columns, identifier });
  }
};

const dynamicgrid = {
  state,
  mutations,
  actions,
  namespaced
};

export default dynamicgrid;
